<template>
  <el-dialog
    custom-class="source"
    :visible.sync="visible"
    lock-scroll
    width="1080px"
    title="随机出卷"
    :fullscreen="fullscreen"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <div class="random-modal yt-flex-layout ">
      <div class="random-first yt-flex-layout flexD">
        <span class="bold-font"><span class="required">*</span><b>设置随机出卷</b></span>
        <div class="yt-flex-layout random-name">
          <div>
            <span style="margin-right: 20px">随机试卷名称</span>
            <el-input style="width: 350px" v-model="randomData.paperName" :maxlength="20" show-word-limit placeholder="请设置试卷名称" />
          </div>
          <div>
            <span style="margin-right: 20px">试卷分数</span>
            <el-input-number v-model="randomData.totalScore" controls-position="right" :precision="0" :min="0" :max="999" />
          </div>
        </div>
      </div>
      <div class="random-second yt-flex-layout flexD">
        <div class="justifyContent">
          <span class="bold-font"><span class="required">*</span><b>选择随机题库</b></span>
          <el-button type="info" class="emptyButton" @click="emptySelect" plain>清空</el-button>
        </div>
        <el-select
          class="random-name"
          style="width: 100%"
          v-model="randomData.selectQBanks"
          multiple
          filterable
          default-first-option
          placeholder="请选择题库"
        >
          <el-option v-for="(b, bIndex) in questionBankList" :key="bIndex" :label="b.name" :value="b.questionBankId" />
        </el-select>
      </div>
      <div class="random-third yt-flex-layout flexD">
        <span class="bold-font"><span class="required">*</span><b>选择专业模型</b></span>
        <el-select class="random-name" style="width: 100%" v-model="post" placeholder="请选择" @change="judgePostCode()">
          <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div v-show="codeFlag" class="random-fourth yt-flex-layout flexD">
        <span class="bold-font"><span class="required"></span><b>可设置代码题数量</b></span>
        <div class="yt-flex-layout random-type">
          <div class="type-only yt-flex-layout" v-for="(a, index) in randomData.questionsNum" :key="index">
            <span class="type-span yt-flex-layout">代码题</span>
            <el-input-number v-model="codeQuestionCount" :min="0" label="描述文字"></el-input-number>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="first-button" type="primary" @click="fullscreen = !fullscreen">
        <JYIcon
          @click="fullscreen = !fullscreen"
          style="color: #FFFFFF;margin-right: 8px;"
          :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
        />
        <span v-text="fullscreen ? '全屏收起' : '全屏编辑'" />
      </el-button>
      <div>
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="define" :loading="loading">确 定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import postApi from '@api/post'
import newPostApi from '@api/newPost'
import questionBankApi from '@api/questionBank'
import paper from '@api/paper'

export default {
  name: 'RandomDialog',
  props: ['bankId', 'examId'],
  data() {
    return {
      fullscreen: false,
      codeFlag: false,
      post: '',
      postData: '',
      codeQuestionCount: 0,
      visible: false,
      loading: false,
      // examId: '',
      paperId: '',
      randomData: {
        paperName: null,
        totalScore: null,
        codeNum: 0,
        selectQBanks: [],
        questionsNum: [
          {
            title: 5,
            key: 'codeQ',
            value: null
          }
        ]
      },
      questionBankList: []
    }
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        this.fullscreen = false
      }
    }
  },
  created() {
    this.getPost()
    this.getBank()
  },
  methods: {
    editorRandomPaper(payload, examId, paperId) {
      // 获取编辑信息
      this.paperId = paperId
      this.examId = examId
      this.post = payload['postId']
      this.randomData['paperName'] = payload['name']
      this.randomData['totalScore'] = payload['totalScore']
      this.randomData['selectQBanks'] = payload['questionBankIds']
      this.judgePostCode()
      this.codeQuestionCount = payload['codeQuestionCount']
    },
    open() {
      this.visible = true
    },
    close(done) {
      done()
    },
    closed() {
      this.post = ''
      this.codeQuestionCount = 0
      this.randomData.paperName = ''
      this.randomData.totalScore = ''
      this.randomData.selectQBanks = []
      this.codeFlag = false
      this.visible = false
      this.loading = false
    },
    emptySelect() {
      this.randomData.selectQBanks = []
    },
    //获取题库
    getBank() {
      questionBankApi.searchSimpleInfoByPermission().then(res => {
        this.questionBankList = res.res
      })
    },
    //获取岗位
    getPost() {
      postApi.getPostByType(true).then(res => {
        this.postData = res.res
      })
    },
    //判断是否有代码题
    judgePostCode() {
      this.codeQuestionCount = 0
      newPostApi.judgeCodeQuestion(this.post).then(res => {
        this.codeFlag = res.res
      })
    },
    define() {
      // 校验必填项
      if (!this.randomData.paperName) return this.$message.warning('随机试卷的名称不可为空！')
      if (!this.randomData.totalScore) return this.$message.warning('随机试卷的总分必须大于0')
      if (this.randomData.selectQBanks.length <= 0) return this.$message.warning('请选择题库')
      if (!this.post) return this.$message.warning('请选择岗位模型')
      // 生成随机试卷
      let payload = {
        codeQuestionCount: this.codeQuestionCount,
        name: this.randomData.paperName,
        examId: this.examId,
        paperId: this.paperId,
        paperBankId: this.bankId,
        postId: this.post,
        questionBankIds: this.randomData.selectQBanks,
        totalScore: this.randomData.totalScore
      }
      this.loading = true
      paper
        .createPostRandom(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('岗位模型试卷规则生成成功')
            this.$emit('on-ok', res.res)
            this.loading = false
            this.visible = false
            this.close()
          } else {
            this.loading = false
            // this.randomData.selectQBanks = []
          }
        })
        .catch(err => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
span {
  text-align: left;
}
.justifyContent {
  display: flex;
  justify-content: space-between;
}
.emptyButton {
  width: 48px;
  height: 20px;
  background: #f0f0f0;
  padding: initial;
  opacity: 1;
  border-radius: 10px !important;
}
.random-modal {
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e2e4e8;
  padding: 20px;
  .number-error {
    border: 1px solid #ff1c00;
  }
  .random-first,
  .random-second,
  .random-third {
    border-bottom: 1px solid #e2e4e8;
    padding-bottom: 20px;
  }
  .random-name {
    //margin: 0 20px;
    //padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
  }
  .random-type {
    padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-number--input {
      width: 190px;
      &:not(:nth-child(3)),
      &:not(:nth-child(6)) {
        margin-right: 0 !important;
      }
      ::v-deep .el-input__inner:focus {
        border-color: #e0e0e0;
      }
    }
    .type-only {
      padding-right: 1px;
      //margin-bottom: 20px;
    }
    .type-span {
      margin-right: 20px;
      width: 49px;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .flexD {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.bold-font {
  .font(14px, bold, #000);
}
.required {
  .font(12px, 500, #f43b3b);
}
@media screen and (max-height: 936px) {
  ::v-deep .source {
    margin-top: 4vh !important;
  }
}
.dialog-footer {
  .flexStyle(flex, space-between, center);
}
::v-deep .el-tag.el-tag--info {
  &:hover {
    background-color: #edf4ff !important;
    color: #448bff;
  }
}
::v-deep .el-select .el-tag__close.el-icon-close {
  &:hover {
    background-color: #448bff;
  }
}
</style>
