import config from '@/config/config'
import { axios } from '@/api/index'

const newExamManageUrl = config.baseUrl + '/exam/api/v2/exam/management'

export default {
  generateExamId(payload) {
    // 生成考试id
    return axios.post(`${newExamManageUrl}/add`, payload)
  },
  allExamList(page, size, form) {
    // 获取所有考试
    return axios.post(`${newExamManageUrl}/search?page=${page}&size=${size}`, form)
  },
  //保存考试和考生信息
  getStudents(payload, examId) {
    return axios.post(`${newExamManageUrl}/add/students?examId=${examId}`, payload)
  },
  // 获取考试的完整信息
  examAllInfo(examId) {
    return axios.get(`${newExamManageUrl}/detail?examId=${examId}`)
  },
  // 发布考试
  postExam(examId) {
    return axios.post(`${newExamManageUrl}/public?examId=${examId}`)
  },
  //展示某一场考试下考生信息
  showStudents(payload) {
    return axios.get(
      `${newExamManageUrl}/examinees?examId=${payload.examId}&name=${payload.name}&sort=${payload.sort}&page=${payload.page}&size=${payload.size}`,
      { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }
    )
  },
  //删除考生
  deleteStudent(payload) {
    return axios.post(`${newExamManageUrl}/examinees/delete?examId=${payload.examId}`, payload.userIds)
  },
  //all删除考生
  deleteStudents(payload) {
    return axios.post(`${newExamManageUrl}/examinees/delete/all?examId=${payload}`)
  },
  //POST /api/v2/exam/management/verify/signature验证签名
  verifySignature(payload) {
    return axios.post(`${newExamManageUrl}/verify/signature`, payload)
  },
  // 外部员工导出接口
  externalStaffExport(examId) {
    return axios.get(`${newExamManageUrl}/download/excel?examId=${examId}`)
  },
  // 删除考试
  delExam(examId) {
    return axios.post(`${newExamManageUrl}/soft/delete`, examId)
  },
  // 更新外部人员时记录的信息
  updateOutsider(examId) {
    return axios.get(`${newExamManageUrl}/external/update?examId=${examId}`)
  },
  // 考试考生
  examineeByExam(payload) {
    return axios.post(`${newExamManageUrl}/exam/user?sort=${payload.sort}&page=${payload.page}&size=${payload.size}`, payload)
  },
  // 进行中的考试可以被关闭
  closeExam(payload) {
    return axios.post(`${newExamManageUrl}/close`, payload)
  },
  /**
   * 工作台
   * **/
  // 统计数量
  statisticsNUm() {
    return axios.get(`${newExamManageUrl}/statistics`)
  },
  // 获取正在进行中的考试
  examNowing() {
    return axios.get(`${newExamManageUrl}/get/underway`)
  },
  // 考试数据分析  折线图
  examDataAnalysis(payload) {
    return axios.post(`${newExamManageUrl}/get/examinee/data`, payload)
  }
}
