<template>
  <div class="exam-preview">
    <div class="exam-item">
      <label>考试名称：</label>
      <span>{{ exam.name }}</span>
    </div>
    <div class="exam-item">
      <label>考试时间：</label>
      <span>{{ exam.isLimitTime ? `${formatTime(exam.startTime)}~${formatTime(exam.endTime)}` : '不限' }}</span>
    </div>
    <div class="exam-item">
      <label>考试时长：</label>
      <span>{{ exam.duration }} 分钟</span>
    </div>
    <div class="exam-item">
      <label>通过条件：</label>
      <span>{{ exam.hasPassCondition ? `不低于总分 ${exam.passRate}%` : '无' }}</span>
    </div>
    <div class="exam-item">
      <label>能否重考：</label>
      <span>{{ exam.permitRepeat ? `最大重考次数 ${exam.repeated}` : '否' }}</span>
    </div>
    <div class="exam-item">
      <label>考前须知：</label>
      <span>{{ exam.beforeExam }}</span>
    </div>
    <div class="exam-item">
      <label>考后须知：</label>
      <span>{{ exam.afterExam }}</span>
    </div>
    <div class="exam-item">
      <label>试卷信息：</label>
      <span>{{ exam.paper.name }}</span>
      <span class="preview" @click="handlePreviewPaper">预览</span>
    </div>
    <Button size="small" style="margin-left: 12px" @click="handlePreStep">上一步</Button>&nbsp;&nbsp;
    <Button type="primary" size="small" @click="handlePublishExam" :loading="loading">发布</Button>
  </div>
</template>

<script>
import filters from '@/util/filterUtils'
import courseApi from '@api/course'

export default {
  name: 'ExamPreview',
  props: {
    exam: {
      type: Object,
      required: true
    },
    courseId: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      loading: false
    }
  },
  methods: {
    formatTime(time) {
      return filters.formatTime(time, 'yyyy-MM-dd hh:mm')
    },
    handlePreviewPaper() {
      window.open(`/manage/exam/paper/preview/${this.exam.paper.paperId}`)
    },
    handlePublishExam() {
      this.loading = true
      // todo 考试类型暂为 1，之后待修改
      courseApi
        .createExam({
          courseId: this.courseId,
          type: 1,
          name: this.exam.name,
          paperId: this.exam.paper.paperId,
          limitTime: this.exam.duration,
          passRate: this.exam.isLimitTime ? this.exam.passRate / 100 : 0,
          repeated: this.exam.repeated,
          startTime: this.exam.startTime,
          endTime: this.exam.endTime,
          messageBeforeExam: this.exam.beforeExam,
          messageAfterExam: this.exam.afterExam
        })
        .then(res => {
          this.$emit('on-success')
        })
    },
    handlePreStep() {
      this.$emit('on-pre-click')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';

.exam-preview {
  .exam-item {
    label {
      padding: 10px 12px;
      display: inline-block;
    }

    .preview {
      color: @primary-color;
      cursor: pointer;
      transition: color @default-transition;
      margin-left: 20px;

      &:hover {
        color: @primary-hover-color;
      }

      &:active {
        color: @primary-active-color;
      }
    }
  }

  button {
    width: 60px;
  }
}
</style>
