import config from '../config/config'
import { axios } from './index'

let organizationUrl = config.baseUrl + '/user/api/v1/org/get/limit'

export default {
  // 组织考试限制
  organizationByExamLimit() {
    return axios.get(`${organizationUrl}/simple`)
  }
}
