<template>
  <div class="methods-style yt-flex-layout">
    <div v-show="!isEditor" :class="paperHasPage ? 'relativeTop' : ''">
      <div :style="isHide ? 'display: none' : 'display: block'" v-if="!paperHasPage">
        <div class="yt-flex-layout h"><h3>请选择出卷方式</h3></div>
        <div class="yt-flex-layout flexWrap">
          <div
            :class="[index % 2 === 0 ? 'div-even' : index < 2 ? 'first-line' : '', selectRow === index ? 'select-row' : '']"
            class="methods-div yt-flex-layout"
            @click="selectRowInfo(index)"
            @mouseenter="selectRow = index"
            v-for="(a, index) in 4"
            :key="index"
          >
            <div><img :src="iconList[index]" alt="" class="jIcon" /></div>
            <div class="desc yt-flex-layout">
              <b>{{ methodsList[index].name }}</b>
              <span>{{ methodsList[index].desc }}</span>
            </div>
            <!--=======-->
            <!--    <div v-show="!isEditor">-->
            <!--      <div class="yt-flexlayout h"><h3>请选择出卷方式</h3></div>-->
            <!--      <div class="yt-flexlayout flexWrap">-->
            <!--        <div-->
            <!--          :class="[index % 2 === 0 ? 'div-even' : index < 2 ? 'first-line' : '', selectRow === index ? 'select-row' : '']"-->
            <!--          class="methods-div yt-flexlayout"-->
            <!--          @click="selectRowInfo(index)"-->
            <!--          @mouseenter="selectRow = index"-->
            <!--          v-for="(a, index) in 4"-->
            <!--          :key="index"-->
            <!--        >-->
            <!--          <div><img :src="iconList[index]" alt="" class="jIcon" /></div>-->
            <!--          <div class="desc yt-flexlayout">-->
            <!--            <b>{{ methodsList[index].name }}</b>-->
            <!--            <span>{{ methodsList[index].desc }}</span>-->
            <!--&gt;>>>>>> saas_dev-->
          </div>
        </div>
      </div>
      <div :style="isHide ? 'display: none' : 'display: block'" class="random-paper" v-else>
        <JYIcon :href="'#icon-zanwushuju'" class="jy-icon" />
        <p>本场考试采用的是随机出卷方式, 没有固定的题目</p>
        <p>您可以配置随机出卷的相关信息, <a @click="editorRandom()">点击设置</a></p>
      </div>
    </div>
    <!--    存在试卷 展示试卷信息-->
    <EditorExam ref="editor" @setId="setId" :name="name" v-show="isEditor" />

    <el-dialog
      custom-class="source"
      :close-on-click-modal="false"
      :title="methodsList[typeIndex].name"
      lock-scroll
      width="1200px"
      :visible.sync="dialogVisible"
      :fullscreen="fullscreen"
    >
      <!-- <SecondStep v-show="nowStep === 2" /> -->
      <component :is="typeSource[typeIndex]" ref="modeComp" :examId="examId" :paperId="paperId" :state="dialogVisible" :fullscreen="fullscreen" />
      <span slot="footer" class="dialog-footer">
        <el-button class="first-button" type="primary" @click="fullscreen = !fullscreen">
          <JYIcon
            @click="fullscreen = !fullscreen"
            style="color: #FFFFFF;margin-right: 10px!important;"
            :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
          />
          <span v-text="fullscreen ? '全屏收起' : '全屏编辑'" />
        </el-button>
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="define" :loading="load">确 定</el-button>
        </div>
      </span>
    </el-dialog>
    <RandomDialog ref="Random" :bankId="bankId" :examId="examId" @on-ok="handleSearchPaper" />
    <AutoPaperDialog ref="AutoPaper" @on-ok="autoCreatePaper" />
    <!--    <el-dialog class="prompt-info" title="提示" width="520px" top="35vh" append-to-body :visible.sync="dialogVisibleT">-->
    <!--      <div class="prompt">-->
    <!--        <div class="format">-->
    <!--          <JYIcon :href="'#icon-jingshi1'" slot="reference" style="width:19px;height:19px;"></JYIcon>-->
    <!--          <span>该试卷已经有考试使用过，系统将新建一份试卷，供您修改</span>-->
    <!--        </div>-->
    <!--        <div class="format">-->
    <!--          <span>试卷名称</span>-->
    <!--          <el-input class="input" v-model.trim="content" maxlength="20" placeholder="默认为考试名称"></el-input>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div slot="footer" class="dialog-footer">-->
    <!--        <div class="prompt-bottom">-->
    <!--          <el-button class="el-button" type="primary" size="small" @click="paperRelation">确定</el-button>-->
    <!--          <el-button class="el-button" size="small" @click="dialogVisibleT = false">取消</el-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import Jy1 from '@/assets/paperPage/选择试卷icon.png'
import Jy2 from '@/assets/paperPage/手动出卷icon.png'
import Jy3 from '@/assets/paperPage/自动出卷icon.png'
import Jy4 from '@/assets/paperPage/随即出卷icon.png'
import EditorExam from '@components/manage/exam/exam/newPaper/EditorExam'
import newExerciseExamApi from '@api/newExerciseExam'
import SelectPaper from '@/components/manage/exam/exam/newPaper/SelectPaper'
import RandomPaper from '@components/manage/exam/exam/newPaper/RandomPaper'
import RandomDialog from '@components/manage/exam/paper/RandomDialog'
import autoPaper from '@components/manage/exam/exam/newPaper/AutoPaper'
import AutoPaperDialog from '@components/manage/exam/exam/newPaper/AutoPaperDialog'
import Manual from '@/components/manage/exam/exam/newPaper/Manual'
import newPaperApi from '@/api/newPaper'
import paperApi from '@/api/paper'
import questionApi from '@api/question'
import examApi from '@api/exam'
import newPaper from '@/api/newPaper'
export default {
  name: 'SelectPaperMethods',
  props: {
    name: String
  },
  components: { JYIcon, EditorExam, RandomDialog, AutoPaperDialog },
  data() {
    return {
      bankId: '',
      loadingExam: null,
      load: false,
      iconList: [Jy1, Jy2, Jy3, Jy4],
      dialogVisible: false,
      content: '',
      dialogVisibleT: false,
      fullscreen: false,
      isHide: true,
      typeSource: [SelectPaper, Manual, autoPaper, RandomPaper],
      methodsList: [
        {
          name: '选择试卷',
          desc: '您可以选择已经出好的试卷，在其基础上进行修改，\n' + '完成试卷制作'
        },
        {
          name: '手动出卷',
          desc: '您可以手动添加题目，并对其进行修改，支持技能、\n' + '知识点、题型等条件进行筛选'
        },
        {
          name: '自动出卷',
          desc: '您可以选择技能，并输入各题型的题数，系统自动为\n' + '您生成一份试卷'
        },
        {
          name: '随机出卷',
          desc: '您可以选择题库和题目数量，系统自动生成试卷，每位考生试卷均不相同'
        }
      ],
      isEditor: false,
      paperHasPage: false,
      examId: null, // 考试id
      paperId: this.$route.query['paperId'] || null, // 试卷id
      typeIndex: 0,
      selectRow: -1
    }
  },
  watch: {
    isEditor: {
      handler(val) {
        val ? this.$refs['editor'].questionInfo() : null
      },
      immediate: true,
      deep: true
    },
    dialogVisible(value) {
      if (value) {
        this.fullscreen = false
      }
    }
  },
  created() {
    this.getPaper()
  },
  methods: {
    getPaper() {
      newPaper.getPaper().then(res => {
        if (res.code === 0) {
          this.bankId = res.res
        }
      })
    },
    setId(id) {
      if (id) {
        this.paperId = id
      }
      this.$emit('setId', id)
    },
    paperRelation() {
      examApi.savePaper(this.$parent.paperId, this.$refs['modeComp'].paperId).then(res => {
        if (res.code === 0) {
          this.editorExam()
          this.loadingMethod()
          this.dialogVisible = false
          this.dialogVisibleT = false
          this.load = false
        }
      })
    },
    loadingMethod() {
      this.loadingExam = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    editorRandomPaper(paperId) {
      // 获取编辑信息
      paperApi.getPostRandom(paperId).then(res => {
        this.$refs.Random.editorRandomPaper(res.res, this.$parent.paperId, paperId)
      })
    },
    editorRandom() {
      // 打开弹框 设置随机出卷(修改)
      // 通过paperId 获取之前设置的数据
      this.typeIndex = 3
      this.$refs.Random.open()
      this.editorRandomPaper(this.paperId)
      // this.dialogVisible = true
    },
    async savePaperStatus() {
      // 试卷保存状态
      let bool = false
      if (this.typeIndex === 3) {
        bool = true
      } else {
        await this.$refs['editor'].savePaper().then(res => {
          bool = res
        })
      }
      return bool
    },
    define() {
      switch (this.typeIndex) {
        //选择题目
        case 0:
          this.getSelectPaper()
          break
        case 1:
          this.getManualPaper()
          break
        case 2:
          this.autoCreatePaper()
          break
        case 3:
          return this.randomPaper()
      }
    },
    randomPaper() {
      // 随机出卷
      let formData = this.$refs['modeComp'].validateForm()
      if (formData.examId) {
        this.load = true
        paperApi.randomPaper(formData).then(res => {
          if (isNaN(res.res)) {
            this.loadingMethod()
            return this.$message.warning(res.res)
          } else {
            this.paperId = res.res
          }
          // 随机出卷  打开试卷之后的页面
          this.paperHasPage = true
          this.load = false
          this.dialogVisible = false
        })
      }
    },
    setList(data) {
      let payload = {
        name: this.name,
        sectionForms: [],
        status: 1
      }
      let array = data
        .map(res => {
          return this.ytConstant.questionType.getLabel(res.questionType)
        })
        .filter((item, index, arr) => arr.indexOf(item) === index)
      array.forEach(res => {
        payload.sectionForms.push({
          name: res,
          questionForms: []
        })
      })
      let info = data.map(res => {
        res.questionType = this.ytConstant.questionType.getLabel(res.questionType)
        return res
      })
      info.forEach(res => {
        payload.sectionForms.forEach(data => {
          if (data.name === res.questionType) {
            data.questionForms.push({ level: 1, questionId: res.id, score: 1, points: 1 })
          }
        })
      })
      paperApi.createNewPaper(this.$parent.paperId, payload).then(res => {
        if (res.code === 0) {
          this.paperId = res.res
          this.editorExam()
          this.loadingMethod()
          this.dialogVisible = false
          this.load = false
        }
      })
    },
    //手动出卷
    getManualPaper() {
      let list = this.$refs['modeComp'].questionSimpleVOS
      if (list.length) {
        this.load = true
        this.setList(list)
      } else {
        this.$message({
          message: '考试题目数量不能为0，请选择',
          type: 'warning'
        })
      }
    },
    //自动出卷
    async autoCreatePaper(questionList) {
      // this.editorExam()
      // const list = this.$refs['modeComp'].abilitySelection
      // if (list.length <= 0) return this.$message.warning('请先设置自动出卷技能设置')
      this.load = true
      // let result = await questionApi.autoCreateExam(list).then(res => {
      //   if (res.code === 0) {
      //     this.loadingMethod()
      //     return res.res
      //   }
      // })
      let payload = {
        name: this.name,
        sectionForms: [
          {
            name: '默认大题',
            questionForms: []
          }
        ],
        status: 1
      }
      questionList.map(res => {
        payload.sectionForms[0].questionForms.push({
          points: 1,
          questionId: res.id,
          score: 1
        })
      })
      paperApi.createNewPaper(this.$parent.paperId, payload).then(res => {
        if (res.code === 0) {
          this.paperId = res.res
          this.editorExam()
          this.dialogVisible = false
          this.load = false
        }
      })
    },
    //选择题目
    getSelectPaper() {
      this.load = true
      const list = this.$refs['modeComp'].questionListC
      this.paperId = this.$refs['modeComp'].paperId
      if (list[0].questionVOS.length) {
        this.paperRelation()
      } else {
        this.$message({
          message: '改试卷下没有题目，请更换',
          type: 'warning'
        })
      }
    },
    editorExam() {
      // 选择完出卷方式之后  开始编辑试卷
      // 使用此方法
      // 请先将后端回传的paperId赋值父组件的paperId
      // 请做校验 试卷一定有题目
      this.judgeHasPaper()
    },
    judgeHasPaper(bool) {
      // 判断是否存在试卷
      if (bool) {
        // 编辑的时候  不显示选择方式
        let res = this.$handleParams('newPaperData')
        this.paperId = res.paperId
        this.isHide = true
      }
      this.examId = this.$parent.paperId
      newExerciseExamApi
        .judgeHasPaperId(this.examId)
        .then(res => {
          // Object.keys(res.res).length !== 0 ? (this.isEditor = true) : (this.isEditor = false)
          if (Object.keys(res.res).length !== 0) {
            // 当type = 2 时  该试卷时随机出的试卷
            if (res.res.type === 4) {
              this.typeIndex = 3 // 随机出卷方式flag 保存校验时需用到
              this.isEditor = false
              this.paperHasPage = true
            } else {
              this.paperHasPage = false
              this.isEditor = true
            }
          } else {
            this.isEditor = false
            this.paperHasPage = false
          }
        })
        .finally(() => {
          if (this.loadingExam) {
            this.loadingExam.close()
          }
          this.isHide = false
        })
    },
    selectRowInfo(index) {
      if (index === 3) {
        this.$refs.Random.open()
        window.sessionStorage.setItem('paperType', '4')
      } else if (index === 2) {
        this.$refs.AutoPaper.open()
      } else {
        this.typeIndex = index
        this.dialogVisible = true
      }
    },
    handleSearchPaper(paperId) {
      this.paperHasPage = true
      this.paperId = paperId
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables.less';
.methods-style {
  justify-content: center;
  height: 100%;
  /*height: calc(100% - 65px);*/
  // height: calc(100vh - 225px);
  .h {
    height: 85px;
    justify-content: center;
  }
  .relativeTop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .random-paper {
    .jy-icon {
      .wh(118px, 101px);
    }
    p {
      margin-top: 10px;
      .font(16px, 500, #000);
      a {
        color: #448bff;
        text-decoration: underline;
      }
    }
  }
}
.flexWrap {
  flex-wrap: wrap;
  width: 840px;
  margin: 0 auto;
  .methods-div {
    padding: 20px;
    border: 2px solid transparent;
    width: 400px;
    height: 120px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    background: #fff;
    cursor: pointer;
    .jIcon {
      color: #448bff;
      .wh(44px, 44px);
    }
    .desc {
      margin-left: 12px;
      flex-direction: column;
      b {
        .font(14px, bold, #333);
        text-align: left;
        margin-bottom: 6px;
      }
      span {
        text-align: left;
        line-height: 22px;
        .font(12px, 500, #666);
      }
    }
  }
  .div-even {
    margin-right: 20px;
  }
  .first-line {
    margin-bottom: 20px;
  }
  .select-row {
    border: 2px solid #448bff;
    box-shadow: 0px 3px 6px rgba(60, 100, 209, 0.5);
  }
}
::v-deep .el-dialog__title {
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 22px !important;
  color: #282c3d !important;
}
.dialog-footer {
  .flexStyle(flex, space-between, center);
}
.source {
  ::v-deep .el-dialog__header {
    padding: 20px 20px 20px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px 10px 20px !important;
  }
}

@media screen and (max-height: 936px) {
  ::v-deep .source {
    margin-top: 4vh !important;
  }
}
.prompt-info {
  ::v-deep .el-dialog__header {
    padding: 20px 20px 20px !important;
    .flexStyle(flex, center, center);
  }
  .prompt {
    .flexStyle(flex, center, center);
    flex-direction: column;
    svg {
      fill: #ff5050;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #000000;
    }
    .format {
      .flexStyle(flex, center, center);
      span {
        white-space: nowrap;
        margin-right: 12px;
      }
      .input {
        width: 332px;
        height: 37px;
        margin-left: 12px;
      }
      ::v-deep .el-input__inner {
        height: 37px;
        line-height: 37px;
      }
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
  .prompt-bottom {
    width: 100%;
    .flexStyle(flex, center, center);
    .el-button {
      width: 80px;
    }
  }
  ::v-deep .el-dialog {
    margin-top: 35vh !important;
  }
}
</style>
