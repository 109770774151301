<template>
  <!--    随机出卷-->
  <div class="random-modal yt-flex-layout ">
    <div class="random-first yt-flex-layout flexD">
      <span class="bold-font"><span class="required">*</span><b>设置随机出卷</b></span>
      <div class="yt-flex-layout random-name">
        <div>
          <span style="margin-right: 20px">随机试卷名称</span>
          <el-input style="width: 350px" v-model="randomData.paperName" :maxlength="20" show-word-limit placeholder="请设置试卷名称" />
        </div>
        <div>
          <span style="margin-right: 20px">总题数量</span>
          <el-input-number v-model="randomData.totalNum" controls-position="right" :precision="0" @change="handleChange" :min="0" :max="100" />
        </div>
      </div>
    </div>
    <div class="random-second yt-flex-layout flexD">
      <span class="bold-font"><span class="required">*</span><b>选择随机题库</b></span>
      <el-select
        class="random-name"
        style="width: 100%"
        v-model="randomData.selectQBanks"
        multiple
        filterable
        default-first-option
        placeholder="请选择题库"
      >
        <el-option v-for="(b, bIndex) in questionBankList" :key="bIndex" :label="b.name" :value="b.questionBankId" />
      </el-select>
    </div>
    <div class="random-third yt-flex-layout flexD">
      <span class="bold-font"
        ><span class="required">*</span><b>设置题目数量({{ randomData.totalNum }}题以内)</b></span
      >
      <div class="yt-flex-layout random-type">
        <div class="type-only yt-flex-layout" v-for="(a, index) in randomData.questionsNum" :key="index">
          <span class="type-span yt-flex-layout">{{ ytConstant.questionType.getLabel(index) }}</span>
          <el-input-number
            class="el-number--input"
            v-model="a.value"
            :precision="0"
            @blur="validateNum($event, index)"
            controls-position="right"
            :min="0"
            :max="100"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questionOld from '@components/common/old/questionOld'
import paperApi from '@api/paper'
export default {
  name: 'RandomPaper',
  props: ['state', 'fullscreen', 'examId', 'paperId'],
  data() {
    return {
      randomData: {
        paperName: null,
        totalNum: null,
        selectQBanks: [],
        questionsNum: [
          {
            title: 0,
            key: 'judgmentQ',
            value: null
          },
          {
            title: 1,
            key: 'choiceQ',
            value: null
          },
          {
            title: 2,
            key: 'multipleChoiceQ',
            value: null
          },
          {
            title: 3,
            key: 'blankQ',
            value: null
          },
          {
            title: 4,
            key: 'shortAnswerQ',
            value: null
          },
          {
            title: 5,
            key: 'codeQ',
            value: null
          }
        ]
      },
      defaultBankId: null,
      questionBankList: []
    }
  },
  created() {
    this.getQuestionBanks()
  },
  methods: {
    editorRandomPaper(payload) {
      // 获取编辑信息
      this.randomData['paperName'] = payload['name']
      this.randomData['totalNum'] = payload['questionAmount']
      this.randomData['selectQBanks'] = payload['questionBankIds']
      Object.keys(payload.questionTypeCountMap).forEach((p, index) => {
        this.randomData.questionsNum[index].value = payload.questionTypeCountMap[p]
      })
    },
    validateForm() {
      // 校验必填项
      // 随机出卷使用的试卷库是默认试卷库
      if (!this.randomData.paperName) return this.$message.warning('随机试卷的名称不可为空！')
      if (!this.randomData.totalNum) return this.$message.warning('随机试卷的总题量必须大于0')
      if (this.randomData.selectQBanks.length <= 0) return this.$message.warning('请选择题库')
      let totalNum = this.randomData.questionsNum.reduce((total, qr) => total + qr.value, 0)
      if (totalNum <= 0) return this.$message.warning('题目数量总和不能为0')
      let payload = {
        examId: this.examId,
        paperId: this.paperId,
        name: this.randomData.paperName,
        questionAmount: this.randomData.totalNum,
        questionBankIds: this.randomData.selectQBanks,
        questionTypeCountMap: {}
      }
      this.randomData.questionsNum.forEach((q, index) => {
        payload.questionTypeCountMap[index] = q.value
      })
      return payload
    },
    validateNum(e, index) {
      // 校验题目是否合法
      this.randomData.questionsNum[index].value = parseInt(e.target.value)
      // 计算设置题目总量
      let totalNum = this.randomData.questionsNum.reduce((total, rq) => total + rq.value, 0)
      if (totalNum > this.randomData.totalNum) {
        this.$message.warning('题型数量不可以超过总题量')
        this.randomData.questionsNum[index].value = 0
      }
    },
    handleChange(value) {
      // 校验题目是否合法
      this.randomData.totalNum = parseInt(value)
      let totalNum = this.randomData.questionsNum.reduce((total, rq) => total + rq.value, 0)
      if (totalNum > value) {
        this.$message.warning('题型数量不可以超过总题量')
        this.randomData.questionsNum.forEach(rq => {
          rq.value = 0
        })
      }
    },
    getQuestionBanks() {
      questionOld
        .getOpenQuestionBanksBymanual('', 0, 999, {
          forked: false,
          searchContent: ''
        })
        .then(res => {
          this.questionBankList = res.res.data
        })
    }
  }
}
</script>

<style lang="less" scoped>
span {
  text-align: left;
}
.random-modal {
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e2e4e8;
  padding: 20px;
  .number-error {
    border: 1px solid #ff1c00;
  }
  .random-first,
  .random-second {
    border-bottom: 1px solid #e2e4e8;
    padding-bottom: 20px;
  }
  .random-name {
    padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
  }
  .random-type {
    padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-number--input {
      width: 190px;
      &:not(:nth-child(3)),
      &:not(:nth-child(6)) {
        margin-right: 0 !important;
      }
      ::v-deep .el-input__inner:focus {
        border-color: #e0e0e0;
      }
    }
    .type-only {
      padding-right: 1px;
      margin-bottom: 20px;
    }
    .type-span {
      margin-right: 20px;
      width: 49px;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .flexD {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.bold-font {
  .font(14px, bold, #000);
}
.required {
  .font(12px, 500, #f43b3b);
}
</style>
