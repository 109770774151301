<template>
  <div class="yt-form yt-flex-layout">
    <el-form class="el-form--style" ref="paperForm" label-width="110px" :model="firstStep" :rules="firstRules" size="small">
      <el-form-item label="考试用途" label-width="100px" prop="external">
        <!--     已存在考试id  不能更改考试用途   -->
        <el-select
          @change="setRepeatState"
          :disabled="Boolean($parent.paperId)"
          clearable
          v-model="firstStep.external"
          size="small"
          placeholder="请选择考试用途"
          class="form-input form-textarea"
        >
          <el-option :value="0" label="内部评测" />
          <el-option :value="1" label="外部招聘" />
        </el-select>
      </el-form-item>
      <div class="yt-flex-layout">
        <el-form-item label="考试名称" prop="name" style="width: 50%;margin-right: 40px">
          <el-input
            @blur="outPutName"
            :maxlength="20"
            show-word-limit
            clearable
            class="form-input"
            v-model.trim="firstStep.name"
            placeholder="请填写考试名称"
          />
        </el-form-item>
        <el-form-item label="专业" prop="postIds" style="width: 50%;">
          <el-select class="form-input" clearable v-model.trim="postId" placeholder="请选择岗位">
            <el-option v-for="(p, index) in postList" :key="index" :label="p.name" :value="p.id" />
          </el-select>
        </el-form-item>
      </div>
      <div class="yt-flex-layout">
        <el-form-item label="考试时长" prop="limitTime" style="width: 50%;;margin-right: 40px">
          <el-input class="form-input" @change="judgeTime" v-model.trim="firstStep.limitTime" placeholder="请输入考试时长">
            <div slot="suffix" style="padding-right: 3px">分钟</div>
          </el-input>
        </el-form-item>
        <el-form-item label="通过比例" prop="passRate" style="width: 50%;">
          <el-input class="form-input" @change="judgePassR" clearable v-model.trim="firstStep.passRate" placeholder="请输入通过比例">
            <div slot="suffix" style="padding-right: 3px">(0-1)</div>
          </el-input>
        </el-form-item>
      </div>
      <div class="yt-flex-layout">
        <el-form-item label="开始时间" style="width: 50%;;margin-right: 40px">
          <el-date-picker
            class="form-input time-style"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            :clearable="false"
            prefix-icon="el-icon-date"
            @blur="firstStep.endTime = null"
            :picker-options="{ disabledDate: checkStartDate }"
            v-model.trim="firstStep.startTime"
            placeholder="请输入开始时间"
          />
        </el-form-item>
        <el-form-item label="结束时间" style="width: 50%;">
          <el-date-picker
            :picker-options="{ disabledDate: checkStartDate }"
            class="form-input time-style"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            :clearable="false"
            @blur="validateEndTime"
            prefix-icon="el-icon-date"
            v-model.trim="firstStep.endTime"
            placeholder="请输入结束时间"
          />
        </el-form-item>
      </div>
      <div class="yt-flex-layout result_div">
        <div class="yt-radio-group div1 yt-flex-layout">
          <span>用户端考试详情查看</span>
          <el-radio-group v-model="firstStep.isView">
            <el-radio :label="0">可查看</el-radio>
            <el-radio :label="1">不可查看</el-radio>
          </el-radio-group>
        </div>
        <div class="yt-radio-group div2 yt-flex-layout">
          <span>用户端考试报告下载</span>
          <el-radio-group v-model="firstStep.isDownload">
            <el-radio :label="0">可下载</el-radio>
            <el-radio :label="1">不可下载</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="yt-flex-layout result_div">
        <div class="yt-radio-group div1 yt-flex-layout">
          <span>是否可以重考</span>
          <el-radio-group :disabled="firstStep.external === 1" v-model="firstStep.repeatState">
            <el-radio :label="1">可重考</el-radio>
            <el-radio :label="0">不可重考</el-radio>
          </el-radio-group>
        </div>
        <div v-show="firstStep.repeatState" style="position:relative;" class="yt-radio-group div2 yt-flex-layout">
          <el-input
            size="small"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            maxlength="3"
            @change="validateNum"
            v-model="firstStep.repeatNum"
            placeholder="请设置可以重考的次数"
            style="width: 100%"
          >
            <template slot="suffix">次</template>
          </el-input>
          <span v-show="!firstStep.repeatNum" style="color: red;position:absolute;top:22px">提示: 输入0表示可以无限次重考</span>
        </div>
      </div>
      <el-form-item label="考试简介" label-width="100px">
        <el-input
          type="textarea"
          class="form-input form-textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :maxlength="250"
          v-model="firstStep.description"
          placeholder="请输入考试的简介..."
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="考前须知" label-width="100px">
        <el-input
          type="textarea"
          class="form-input form-textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :maxlength="300"
          v-model="firstStep.messageBeforeExam"
          placeholder="请输入考前须知..."
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="考后须知" label-width="100px">
        <el-input
          type="textarea"
          class="form-input form-textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :maxlength="300"
          v-model="firstStep.messageAfterExam"
          placeholder="请输入考后须知..."
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import newExamManageApi from '@api/newExamManage'
import postApi from '@api/post'
export default {
  name: 'FirstStep',
  data() {
    let validatePass = (rule, value, callback) => {
      if (this.firstStep.postIds && this.firstStep.postIds[0] === null) {
        callback(new Error('请选择岗位'))
      } else {
        callback()
      }
    }
    return {
      firstStep: {
        repeatState: 0, // 0 不可以重考
        repeatNum: null, // 重考时设置次数  不可重考是null  可以重考最低是1
        external: 0,
        isView: 1,
        isDownload: 1,
        name: null,
        examId: null,
        description: null,
        messageBeforeExam: null,
        messageAfterExam: null,
        limitTime: null,
        passRate: null,
        passCount: null,
        startTime: null,
        endTime: null,
        postIds: null
      },
      postList: [],
      postId: null,
      firstRules: {
        external: {
          required: true,
          message: '请选择考试用途',
          trigger: 'blur'
        },
        name: [{ required: true, message: '测评名称不能为空', trigger: 'blur' }],
        limitTime: [
          {
            required: true,
            message: '考试时长不能为空',
            trigger: 'blur'
          }
        ],
        passRate: [{ required: true, message: '请设置通过比列', trigger: 'blur' }],
        postIds: [{ required: true, validator: validatePass, trigger: 'change' }]
      }
    }
  },
  watch: {
    postId: {
      handler(val) {
        this.firstStep.postIds = []
        this.firstStep.postIds.push(val)
      },
      deep: true,
      immediate: true
    },
    'firstStep.external': {
      handler(val) {
        this.$parent.examType = val
      },
      immediate: true,
      deep: true
    },
    'firstStep.repeatState'(val) {
      if (!val) {
        this.firstStep.repeatNum = null
      }
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    setRepeatState(data) {
      this.firstStep.repeatState = Number(!data)
    },
    validateNum(num) {
      if (Number(num) < 0 || num === '') return (this.firstStep.repeatNum = 1)
      if (parseFloat(num).toString() === 'NaN') {
        this.firstStep.repeatNum = null
        return
      }
      this.firstStep.repeatNum = parseInt(num) // 取整
    },
    examInfo(examId) {
      // 编辑考试的时候需要使用改方法
      newExamManageApi.examAllInfo(examId).then(res => {
        if (!res.res['external']) res.res['external'] = 0
        this.$emit('getName', res.res.name)
        window.sessionStorage.setItem('paperType', res.res.paperType)
        Object.keys(this.firstStep).forEach(r => {
          if (r === 'passRate') {
            this.firstStep['passRate'] = res.res['passScore']
          } else this.firstStep[r] = res.res[r]
        })
        if (res.res['postNames'].length > 0) {
          this.postList.forEach(p => {
            if (p.name === res.res['postNames'][0]) {
              this.postId = p.id || null
            }
          })
        }
      })
    },
    checkStartDate(date) {
      return Date.now() > date.getTime() + 24 * 60 * 60 * 1000
    },
    validateEndTime() {
      if (this.firstStep.endTime && this.firstStep.endTime <= this.firstStep.startTime) {
        this.firstStep.endTime = null
        return this.$message.warning('结束时间请大于开始时间')
      }
    },
    judgePassR(e) {
      // 校验通过比列
      let a = Number(e)
      if (isNaN(a) || a <= 0 || a > 1) return (this.firstStep.passRate = 0.6)
    },
    outPutName() {
      this.$emit('getName', this.firstStep.name)
    },
    judgeTime(e) {
      // 校验时长 不大于999
      let a = Number(e)
      if (isNaN(a) || a <= 0 || a > 999) return (this.firstStep.limitTime = 90)
    },
    getPosts() {
      postApi.getAllPosts().then(res => {
        this.postList = res.res
      })
    },
    async submitFirstStep() {
      let b = false
      if (this.firstStep.repeatState === 1 && (this.firstStep.repeatNum === null || this.firstStep.repeatNum === undefined))
        return this.$message.warning('重考次数必填')
      let a = await this.$refs['paperForm'].validate()
      this.firstStep['examId'] = this.$parent.paperId
      if (a) {
        await newExamManageApi.generateExamId(this.firstStep).then(res => {
          this.$parent.paperId = res.res
          b = true
        })
      }
      return b
    }
  }
}
</script>

<style lang="less" scoped>
.yt-flex-layout {
  justify-content: space-between;
}
.yt-form {
  padding: 20px 0 10px 0;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /*height: calc(100% - 60px);*/
}
.el-form--style {
  margin: 0 auto;
  width: 65%;
  .form-input {
    width: 100%;
    border-radius: 2px;
    border-color: #d7d7d7;
    ::v-deep .el-input {
      width: 100% !important;
    }
  }
  .form-textarea:focus {
    border-color: #4579ea;
  }
  .result_div {
    height: 60px;
    justify-content: flex-start;
    align-items: flex-start;
    .font(14px, Regular, #000);
    .yt-radio-group {
      padding-left: 20px;
      width: 50%;
      height: 30px;
      justify-content: flex-start;
      align-items: center;
      span {
        line-height: 48px;
        margin-right: 12px;
      }
    }
    .div2 {
      padding-left: 38px;
    }
  }
}
::v-deep {
  .time-style {
    .el-input__inner {
      padding-left: 10px !important;
    }
    .el-input__prefix {
      right: 5px !important;
      left: auto !important;
    }
  }
  .el-form-item__content {
    width: 100%;
  }
  .el-form-item__label {
    white-space: nowrap;
  }
  .el-form-item {
    display: flex;
    align-content: center;
    margin-bottom: 40px !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-input__suffix-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      line-height: 38px;
    }
  }
  .el-input__suffix-inner {
    height: 100%;
  }
  .el-input__inner,
  .el-textarea__inner {
    font-size: 14px;
    &::placeholder {
      .font(14px, Regular, #999);
    }
  }
}
</style>
