<template>
  <div class="staff-container">
    <div class="staff-top">
      <div>
        考试对象:
        <span>{{ $parent.examType ? '外部人员' : '内部人员' }}</span>
      </div>
      <div v-if="showinfo" class="limit_tip yt-flex-layout">
        <div>
          <span>考试人次限制:</span>
          <span class="blue_span" style="margin-left: 10px">{{ limitPayload.totalCount }}</span>
        </div>
        <div style="margin-left: 20px">
          <span>已使用人次数:</span>
          <span class="red_span" style="margin-left: 10px">{{ limitPayload.usageCount }}</span>
        </div>
        <div style="margin-left: 20px">
          <span>剩余人次数:</span>
          <span class="blue_span" style="margin-left: 10px">{{ limitPayload.unusedCount }}</span>
        </div>
      </div>
      <div style="display:flex;" class="functionButton">
        <el-input
          placeholder="通过人员名称模糊搜索"
          style="margin-right:12px;width:274px;"
          v-model="payload.name"
          @keydown.enter.native="
            payload.page = 0
            judge()
          "
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer;"
            @click="
              payload.page = 0
              judge()
            "
          />
        </el-input>
        <el-button class="el-button" type="primary" size="small" @click="newEmployee">
          <YTIcon @click="newEmployee" :href="'#icon-xinzeng'" style="width: 16px;height: 17px;margin-right: 5px;" />新增
        </el-button>
        <el-button class="el-button-d" style="margin-left: 10px; width: 115px;" size="small" @click="delStaff">
          <YTIcon @click="delStaff" :href="'#icon-shanchu'" style="width: 16px;height: 15px;margin-right: 10px;color: #999999;" /><span
            >批量删除</span
          >
        </el-button>
        <el-button v-show="$parent.examType" class="el-button" @click="importPeople()" style="margin-left: 10px;" size="small">
          <YTIcon @click="importPeople()" :href="'#icon-daoru1'" style="width: 16px;height: 17px;margin-right: 5px;color: #999999;" />
          <span>导入</span>
        </el-button>
        <el-button class="el-button" size="small" style="margin-left: 10px;" @click="clearStaff">
          <YTIcon @click="clearStaff" :href="'#icon-qingkong'" style="width: 16px;height: 17px;margin-right: 5px;color: #999999;" /><span
            >清空</span
          ></el-button
        >
      </div>
    </div>
    <div class="staff-table yt-table" v-loading="loading">
      <el-table
        v-loading="loading"
        v-show="!$parent.examType && !loading && tableData.length"
        :row-key="getRowKey"
        @selection-change="rowChange"
        :data="tableData"
        style="width: 100%"
        ref="listPowerSupplyTab"
      >
        <el-table-column type="selection" width="30" />
        <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
        <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
        <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
        <el-table-column label="专业" prop="postName" show-overflow-tooltip />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="tool-button" @click="toDelete(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-show="$parent.examType && !loading && tableDataExter.length"
        :row-key="getRowKey"
        @selection-change="rowChange"
        :data="tableDataExter"
        style="width: 100%"
      >
        <el-table-column type="selection" width="30" />
        <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
        <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
        <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
        <el-table-column label="邮件状态" prop="sendStatus">
          <template slot-scope="scope">
            <span>{{ scope.row.sendStatus ? '已发' : '未发' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="tool-button" @click="toDelete(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <Page
        v-show="tableData.length || tableDataExter.length"
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        :total="total"
        :current="pageNum + 1"
        :page-size-opts="[10, 20, 40, 100]"
        :page-size="pageSize"
        @on-change="changePage"
        @on-page-size-change="changePageSize"
        show-elevator
        show-sizer
        show-total
      />
      <div class="no-hint" v-show="!tableData.length && !tableDataExter.length && !loading">
        <YTIcon :href="'#icon-zanwushuju'" slot="reference" style="width:120px;height:124px;"></YTIcon>
        <p>暂时没有内容</p>
      </div>
    </div>

    <el-dialog
      title="新增考试人员"
      :close-on-click-modal="false"
      destroy-on-close
      :width="!$parent.examType ? '1080px' : '600px'"
      :visible.sync="dialogVisible"
    >
      <AddExam ref="addExam" :limitPayload="limitPayload" :examId="$parent.paperId" :checkIn="tableData" v-if="!$parent.examType" />
      <ExternalAdd :limitPayload="limitPayload" v-else ref="externalAdd" />
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button class="el-button" type="primary" :loading="addStaffLoading" size="small" @click="determine">确定</el-button>
          <el-button class="el-button" size="small" @click="dialogVisible = false">取消</el-button>
        </div>
      </span>
    </el-dialog>

    <ConfirmDialog ref="delConDialog" :title="`${status ? '清空' : '删除'}考生`" @on-ok="delQuestionRows">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        {{ `此操作将删除${status ? '所有' : '该'}考生, 是否继续?` }}
      </div>
    </ConfirmDialog>

    <el-dialog title="导入考试人员" width="520px" :visible.sync="theImport">
      <div class="uploadMd">
        <div class="upload">
          <span>上传文件</span>
          <el-upload class="" action="" :show-file-list="false" :before-upload="handleSuccess" :limit="1" v-show="$parent.examType">
            <el-button class="el-button" style="width: 350px;height: 37px;margin-left: 10px;" size="small"
              ><YTIcon
                :href="'#icon-daoruwenjian-gaoliangzhuangtaiicon'"
                v-show="!file"
                style="width: 19px;height: 14px;margin-right: 5px; color:#CCCCCC;"
              />{{ file ? file.name : '请上传文件格式为xls，大小不超过5M' }}</el-button
            >
          </el-upload>
        </div>
        <div>
          <p>
            1.请根据系统提供的模板导入，<span> 点击 </span
            ><a href="javascript:void(0);" @click="downloadExternal">
              下载模板
            </a>
          </p>
          <p>2.文件仅限excel类型，文件格式为xls，大小不超过5M</p>
        </div>
        <div v-if="limitPayload" class="limit_tip" style="text-align: left;padding-left: 7px;margin-top: 5px">
          <span>剩余人次数</span>
          <span class="blue_span" style="margin-left: 10px">{{ limitPayload.unusedCount }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button class="el-button" type="primary" size="small" :loading="fileState" @click="uploadDetermine">确定</el-button>
          <el-button class="el-button" size="small" @click="cancel">取消</el-button>
        </div>
      </span>
    </el-dialog>

    <ConfirmDialog ref="switchDialog" :title="`提示`" @on-ok="switchObj">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        {{ `此操作将切换到添加${testObject}当前数据将会被清空, 是否继续?` }}
      </div>
    </ConfirmDialog>

    <ConfirmDialog ref="delGroupDialog" :title="`删除考生`" @on-ok="batchDele">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        {{ `此操作将删除选中的考生, 是否继续?` }}
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import AddExam from '@/components/manage/exam/exam/newPaper/AddExam'
import newExamManageApi from '@/api/newExamManage'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import YTIcon from '@/components/common/YTIcon'
import ExternalAdd from '@/components/manage/exam/exam/newPaper/ExternalAdd'
import externalEmployeesApi from '@/api/externalEmployees'
import organization from '@api/organization'
export default {
  name: 'InternalStaff',
  components: { AddExam, ConfirmDialog, YTIcon, ExternalAdd },
  data() {
    return {
      showinfo: false,
      fileState: false,
      loading: true,
      staff: '内部员工',
      testObject: null,
      file: null,
      theImport: false,
      status: 0,
      dialogVisible: false,
      addStaffLoading: false,
      total: 0,
      pageNum: 0,
      userId: 0,
      pageSize: 10,
      staffList: [],
      selectedUserId: [], // 已选中的人员
      tableData: [],
      tableDataExter: [],
      examId: null,
      paperId: null,
      limitPayload: {
        organizationExamLimitId: null, // id
        totalCount: null, // 可参与总数
        unusedCount: null, // 未参与数量
        usageCount: null // 已参与的数量
      },
      payload: {
        examId: 0,
        sort: 'asc',
        page: 0,
        size: 10,
        name: ''
      },
      userIds: []
    }
  },
  watch: {
    dialogVisible(value) {
      if (value && !this.$parent.examType) {
        this.$nextTick(() => {
          this.$refs.addExam.getStaffList()
        })
      }
    }
  },
  mounted() {
    if (this.$route.name === 'newPaper' && this.$parent.nowStep === 1) {
      this.loading = false
    }
    this.getOrganizationLimitInfo()
    // if (this.$route.params.examId) {
    //   this.determineTheCategory()
    // }
  },

  methods: {
    getOrganizationLimitInfo() {
      // 获取该组织下考试限制的次数
      organization.organizationByExamLimit().then(res => {
        if (!res.res) {
          this.limitPayload = null
          this.showinfo = false
        } else {
          this.showinfo = true
          Object.keys(this.limitPayload).forEach(e => {
            this.limitPayload[e] = res.res[e]
          })
        }
      })
    },
    updateOutsiderData() {
      // 外部人员数据更新保存需记录
      if (this.$parent.examType) {
        newExamManageApi.updateOutsider(this.$parent.paperId)
      }
    },
    getRowKey(row) {
      return row.id
    },
    importPeople() {
      if (this.limitPayload && this.limitPayload.unusedCount === 0) return this.$Message.warning('该企业考试剩余次数已用完，无法添加人员')
      this.theImport = true
    },
    newEmployee() {
      if (this.limitPayload && this.limitPayload.unusedCount === 0) return this.$Message.warning('该企业考试剩余次数已用完，无法添加人员')
      this.examId = this.$parent.examId
      this.paperId = this.$parent.paperId
      this.dialogVisible = true
    },
    determineTheCategory() {
      newExamManageApi.examAllInfo(this.$parent.paperId).then(res => {
        if (!res.res['external']) res.res['external'] = 0
        this.$parent.examType = res.res['external'] // 设置考试类型  记录在父组件公共变量中
        this.payload.size = 10
        if (res.res['external']) {
          // 外部
          this.getExternalUser()
        } else {
          // 内部
          this.getShowStudents()
        }
      })
    },
    downloadExternal() {
      externalEmployeesApi.downloadExternal().then(res => {
        this.$downloadFlow(res, '外部员工模板', 'xls')
      })
    },
    judge() {
      if (!this.$parent.examType) {
        this.getShowStudents()
      } else {
        this.getExternalUser()
      }
    },
    handleSuccess(file) {
      const suffix = file.name.split('.')[1]
      if (suffix === 'xls') {
        this.file = file
      } else {
        this.$message({
          type: 'warning',
          message: '仅支持xls格式文件'
        })
      }
      return false
    },
    uploadDetermine() {
      if (this.file) {
        this.fileState = true
        let formData = new FormData()
        formData.append('file', this.file)
        externalEmployeesApi.uploadExternal(this.$parent.paperId, formData).then(res => {
          if (res.code === 0) {
            this.getExternalUser()
            this.getOrganizationLimitInfo()
            this.$message({
              type: 'success',
              message: '导入成功'
            })
            this.fileState = false
            this.file = null
            this.theImport = false
          } else {
            this.fileState = false
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请选择上传文件'
        })
      }
    },
    cancel() {
      this.file = null
      this.theImport = false
    },
    changePage(page) {
      this.payload.page = page - 1
      this.judge()
    },
    changePageSize(Size) {
      this.payload.size = Size
      this.judge()
    },
    getShowStudents() {
      this.loading = true
      this.payload.examId = this.$parent.paperId
      newExamManageApi
        .showStudents(this.payload)
        .then(res => {
          if (res.code === 0) {
            this.tableData = [...res.res.data]
            this.total = res.res.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async determine() {
      this.addStaffLoading = true
      let a = false
      if (!this.$parent.examType) {
        const list = this.$refs.addExam.numberList
        let userId = list.map(res => {
          return res.userId
        })
        if (list.length) {
          await newExamManageApi
            .getStudents(userId, this.$parent.paperId)
            .then(res => {
              if (res.code === 0) {
                this.payload.examId = this.$parent.paperId
                this.getShowStudents()
                this.getOrganizationLimitInfo()
                this.dialogVisible = false
                a = true
              }
            })
            .finally(() => {
              this.addStaffLoading = false
            })
        } else {
          this.$message({
            message: '考试人数不能为0，请选择考试人数',
            type: 'warning'
          })
          this.addStaffLoading = false
        }
      } else {
        const form = this.$refs.externalAdd.submitForm()
        if (form) {
          Object.defineProperty(form, 'examId', {
            value: this.$parent.paperId,
            writable: true,
            enumerable: true,
            configurable: true
          })
          await externalEmployeesApi.addExternal(form).then(res => {
            if (res.code === 0) {
              this.payload.examId = this.$parent.paperId
              this.getExternalUser()
              this.getOrganizationLimitInfo()
              this.dialogVisible = false
              a = true
            }
          })
        }
        this.addStaffLoading = false
      }
      return a
    },
    //获取外部人员列表
    getExternalUser() {
      this.loading = true
      this.payload.examId = this.$parent.paperId
      externalEmployeesApi
        .getExternalUser(this.payload)
        .then(res => {
          if (res.code === 0) {
            this.tableDataExter = [...res.res.data]
            this.total = res.res.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearStaff() {
      this.status = 1
      this.$refs['delConDialog'].open()
    },
    switchObj() {
      this.payload.page = 0
      switch (this.testObject) {
        case '内部员工':
          this.staff = this.testObject
          this.emptyStudentsWei()
          break
        case '外部员工':
          this.staff = this.testObject
          this.emptyStudentsNei()
          break
      }
    },
    delStaff() {
      this.$refs['delGroupDialog'].open()
    },
    selectObj(data) {
      this.testObject = data
      if ((data === '外部员工' && this.tableData.length > 0) || (data === '内部员工' && this.tableDataExter.length > 0)) {
        this.$refs['switchDialog'].open()
      } else {
        this.switchObj()
      }
    },
    toDelete(data) {
      this.status = 0
      this.userId = data.userId
      this.$refs['delConDialog'].open()
    },
    delQuestionRows() {
      if (this.status) {
        this.deleteStudents()
      } else {
        this.deleteStudent(this.userId)
      }
    },
    batchDele() {
      if (this.userIds.length) {
        !this.$parent.examType ? this.deleteInside() : this.deleteExternal()
      } else {
        this.$message({
          message: '请选择要删除的考生',
          type: 'warning'
        })
      }
      // this.dialogGroupDelVisible = false
    },
    //删除单个
    deleteStudent(userId) {
      if (!this.$parent.examType) {
        this.deleteInside(userId)
      } else {
        this.deleteExternal(userId)
      }
    },
    deleteInside(userId) {
      let payload = {
        examId: this.payload.examId,
        userIds: userId ? [userId] : this.userIds
      }
      newExamManageApi.deleteStudent(payload).then(res => {
        if (res.code === 0) {
          this.getShowStudents()
          this.getOrganizationLimitInfo()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    /**
     *
     * @param userId 参数传了该值  说明是 非批量删除
     */
    deleteExternal(userId) {
      externalEmployeesApi.deleteExternal(userId ? [userId] : this.userIds, this.$parent.paperId).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getOrganizationLimitInfo()
          this.getExternalUser()
        }
      })
    },
    //清空
    deleteStudents() {
      if (!this.$parent.examType) {
        this.emptyStudentsNei()
      } else {
        this.emptyStudentsWei()
      }
    },
    //内部员工
    emptyStudentsNei() {
      newExamManageApi.deleteStudents(this.payload.examId).then(res => {
        if (res.code === 0) {
          this.getShowStudents()
          this.getOrganizationLimitInfo()
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // })
        }
      })
    },
    //外部员工
    emptyStudentsWei() {
      externalEmployeesApi.deleteExternalId(this.payload.examId).then(res => {
        if (res.code === 0) {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // })
          this.getExternalUser()
          this.getOrganizationLimitInfo()
        }
      })
    },
    rowChange(selection) {
      this.userIds = selection.map(res => {
        return res.userId
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables.less';
.staff-container {
  // min-height: 660px;
  .limit_tip {
    align-items: center;
    .blue_span {
      color: #448bff;
    }
    .red_span {
      color: #ff1c00;
    }
  }
  padding: 20px;
  .staff-top {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    .flexStyle(flex, space-between, center);
    .select {
      margin-left: 12px;
      height: 38px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }
    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
    .functionButton {
      span {
        color: #666666;
      }
    }
  }
  .staff-table {
    height: calc(100vh - 356px);
    margin-top: 20px;
    .no-hint {
      height: calc(100vh - 419px);
      .flexStyle(flex, center, center);
      flex-direction: column;
    }
  }
}
.el-button {
  width: 80px;
  height: 32px;
}
.dialog-footer {
  .flexStyle(flex, center, center);
}
::v-deep .el-dialog__body {
  padding: 30px !important;
}
.uploadMd {
  .upload {
    .flexStyle(flex, center, center);
    margin-bottom: 10px;
  }
  p {
    text-align: left;
    margin-left: 86px;
    line-height: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #cccccc;
    span {
      color: #000000;
    }
    a {
      font-weight: 400;
      line-height: 24px;
      color: #448bff;
      border-bottom: 1px solid;
    }
  }
}
// ::v-deep .el-table {
//   height: calc(100vh - 453px);
// }
// @media screen and (max-height: 936px) {
//   ::v-deep .el-dialog {
//     /*margin-top: 4vh !important;*/
//   }
// }
</style>
