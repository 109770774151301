<template>
  <div class="yt-main yt-flex-layout">
    <div class="yt-container" :class="{ 'is-pack-up': isPackUps }" style="padding: 10px 0 0 0;">
      <div class="yt-content">
        <!--        <div class="yt-content-top">-->
        <!--          <div class="question-order">-->
        <!--            <span>试卷名称: </span-->
        <!--            ><el-input :maxlength="20" show-word-limit v-model="papernew.paperName" style="text-align: left; width: calc(100% - 40px);" />-->
        <!--          </div>-->
        <!--        </div>-->
        <!--全选-->
        <div class="select-all">
          <el-checkbox v-if="selectedQuestionIds.length > 0" :indeterminate="isIndeterminate" v-model="isSelectAll" @change="handleCheckAllChange"
            >全选题目</el-checkbox
          >
          <div class="btn-style">
            <el-button @click="addQuestionsModal()" type="primary" size="small">
              <div>
                <YTIcon @click="addQuestionsModal()" :href="'#icon-xinzeng'" />
                <p>增加题目</p>
              </div>
            </el-button>
            <el-button @click="delBatch" size="small" class="btn-del">
              <div>
                <YTIcon @click="delBatch" :href="'#icon-shanchu'" style="fill: #999" />
                <p>批量删除</p>
              </div>
            </el-button>
            <el-button @click="storageQ" size="small" class="btn-del">
              <div>
                <YTIcon @click="storageQ" :href="'#icon-baocun'" style="fill: #999" />
                <p>存为试卷</p>
              </div>
            </el-button>
          </div>
        </div>
        <div class="question-container" v-loading="questionListLoading" element-loading-text="加载中">
          <div
            class="list"
            :style="{
              height: questionListLoading ? '100%' : 'auto',
              overflowY: questionListLoading ? 'hidden' : 'auto'
            }"
          >
            <!--题目卡片-->
            <template>
              <div v-for="(item, index) in questionList">
                <div v-if="item.questionScores.length > 0">
                  <div class="question-sort">
                    <p>{{ item.name }}</p>
                    <p style="color: #448BFF">
                      {{ item.questionScores.length }}
                    </p>
                    <p>题</p>
                    <el-input
                      maxLength="3"
                      style="margin-right: 5px"
                      oninput="value=value.replace(/[^\d.]/g,'')"
                      v-model="item.totalScore"
                      @blur="setBigTopicPoint(item.totalScore, index)"
                      @keydown.enter.native="setBigTopicPoint(item.totalScore, index)"
                    />
                    <p>分</p>
                  </div>
                  <div
                    @mouseenter="selectRowId = question.id"
                    v-for="(question, qIndex) in questionList[index].questionScores"
                    :key="qIndex"
                    class="item"
                    :id="'anchor-' + question.id"
                  >
                    <template v-for="item in questionAll" v-if="item.id === question.id">
                      <el-checkbox class="checkbox" v-model="item.isChecked" @change="handleCheckQuestion($event, question.id)" />
                    </template>
                    <div style="width: 100%">
                      <!--题目卡片头部-->
                      <div class="header">
                        <div class="question-type">
                          {{ ytConstant.questionType.getLabel(question.questionDetail.questionType)[0] }}
                        </div>
                      </div>
                      <div class="body">
                        <!--题干-->
                        <div class="stem">
                          {{ qIndex + 1 }}.&nbsp;
                          <div class="info" v-html="question.questionDetail.stem"></div>
                        </div>
                        <component :is="questionComponents[question.questionDetail.questionType]" :question="question" style="margin-top: 16px" />
                        <div class="correct-answer">
                          <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                          <div>{{ setAnswer(question.questionDetail) }}</div>
                        </div>
                        <div style="display: flex">
                          <div style="flex-shrink: 0">
                            <span style="letter-spacing: 12px">解</span>
                            <span style="letter-spacing: 5px">析:</span>
                          </div>
                          <div v-html="question.questionDetail.analysis"></div>
                        </div>
                        <div class="tag-and-knowledge" ref="tagAndKnowledge">
                          <div class="tk-item tk-select-item">
                            <span style="letter-spacing: 12px">标</span>
                            <span style="letter-spacing: 5px">签:</span>
                            <template v-for="(tag, tIndex) in question.questionDetail.tags">
                              <div :key="tIndex">
                                <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                                  <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                                    {{ tag.name }}
                                  </div>
                                </el-tooltip>
                              </div>
                            </template>
                            <el-popover
                              placement="top"
                              width="202"
                              trigger="hover"
                              title="题目标签"
                              v-if="
                                question.questionDetail.hasOwnProperty('tags') &&
                                  question.questionDetail.tags &&
                                  showSize < question.questionDetail.tags.length
                              "
                            >
                              <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                                <template v-for="(tagPop, tPopIndex) in question.questionDetail.tags">
                                  <div :key="tPopIndex">
                                    <el-tooltip
                                      effect="dark"
                                      :disabled="tagPop.name.length < 4"
                                      :content="tagPop.name"
                                      placement="top"
                                      :open-delay="1000"
                                    >
                                      <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                        {{ tagPop.name }}
                                      </div>
                                    </el-tooltip>
                                  </div>
                                </template>
                              </div>
                              <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px" />
                            </el-popover>
                          </div>
                        </div>
                      </div>
                      <!--题目卡片底部-->
                      <div class="footer">
                        <div>
                          <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.questionDetail.difficulty / 10 }}</p>
                          <p slot="reference" class="popover-reference">
                            使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount || 0 }}
                          </p>
                          <p slot="reference" class="popover-reference" style="display: flex;flex-flow: row">
                            设置分<span style="letter-spacing: 5px">数:</span>
                            <el-input
                              maxLength="3"
                              oninput="value=Number(value.replace(/[^\d.]/g,''))"
                              v-model="question.score"
                              @input="setSmallTopicPoint(question.id, question.score, question.questionDetail.questionType)"
                            />
                            <span style="margin-left: 6px">分</span>
                          </p>
                          <!--                          oninput="value=Number(value.replace(/[^\d.]/g,''))"-->
                          <!--                          onkeyup="let v=this.value||'';if(v<1||v===''){this.value=0.5;}else if(v>1&&v.toString().indexOf('.')!==-1){this.value=Number(Math.floor(v));} "-->
                        </div>
                        <div @click="toDelete(question, index, qIndex)" style="cursor: pointer;" :loading="deleteLoading">
                          <span style="right: 5px">
                            <YTIcon @click="toDelete(question, index, qIndex)" :href="'#icon-yichu'" :loading="deleteLoading" />
                            移除
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="empty"
                :style="{
                  height: 'calc(100vh - 303px)'
                }"
                v-if="questionAllid.length <= 0"
              >
                <template v-if="!questionListLoading" :style="height">
                  <YTIcon :href="'#icon-zanwushuju'" />
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="yt-right--navigation" :style="{ width: shrink ? '' : '30%', minWidth: shrink ? '' : '227px' }">
      <YTIcon
        :href="'#icon-shouqi'"
        @click="shrink = !shrink"
        :style="{
          fontSize: '18px',
          transform: shrink ? 'rotate(0deg)' : 'rotate(180deg)'
        }"
      />
      <div v-show="!shrink" style="width: 100%;">
        <div class="header yt-flex-layout" style="justify-content: space-between;padding-right: 10px">
          <span style="color: #333">
            总题量
            <span style="color: #448bff">{{ papernew.questionSimpleVOS.length }}</span>
            题
          </span>
          <span style="color: #333"
            >总分
            <span style="color: #448bff">{{ papernew.totalPoints }}</span>
            分</span
          >
        </div>
        <hr class="hr-style" />
        <div class="question-type">
          <div class="q-style" v-for="(q, index) in questionList" :key="index">
            <div v-if="q.questionScores.length > 0">
              <div class="yt-flex-layout" style="justify-content: space-between">
                <div class="yt-flex-layout" style="align-items: center">
                  <div class="type" />
                  <span class="span">{{ q.name }}</span>
                  <el-input
                    oninput="value=value.replace(/[^\d.]/g,'')"
                    @keydown.enter.native="setBigTopicPoint(q.totalScore, index)"
                    @blur="setBigTopicPoint(q.totalScore, index)"
                    style="max-width: 60px;height: 20px;margin: 0 5px"
                    v-model="q.totalScore"
                  />
                  <span>分</span>
                </div>
                <div class="del-svg">
                  <YTIcon @click="delQuestion(q)" :href="'#icon-shanchu'" style="width: 15px;height: 15px;fill: #999" />
                </div>
              </div>
              <div class="question-number yt-flex-layout">
                <div
                  @click="selectorById(qs)"
                  :class="selectRowId === qs.id ? 'selectRow' : ''"
                  class="number yt-flex-layout flexCenter"
                  v-for="(qs, sIndex) in q.questionScores"
                  :key="sIndex"
                >
                  {{ sIndex < 9 ? '0' : null }}{{ sIndex + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="delConDialog" class="delModal" :title="'删除题目'" @on-ok="delQuestionRows">
      <div style="padding: 40px 0 0 0;justify-content: center;" class="yt-flex-layout">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        <span style="text-align: left;margin-left: 8px">确定删除该题型下的所有题目吗,删除后不可恢复</span>
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="delBatchDialog" class="delModal" :title="'批量删除题目'" @on-ok="moveBatch">
      <div style="padding: 40px 0 0 0;justify-content: center;" class="yt-flex-layout">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        <span style="text-align: left;margin-left: 8px">{{ `此操作将删除所有选中的题目, 是否继续?` }}</span>
      </div>
    </ConfirmDialog>
    <el-dialog title="选择题目" :visible.sync="choiceQuestions" width="1080px" :fullscreen="fullscreen" style="text-align: center">
      <Manual ref="manual" :state="choiceQuestions" :fullscreen="fullscreen" />
      <div slot="footer" class="yt-flex-layout" style="justify-content: space-between">
        <el-button class="first-button" type="primary" @click="fullscreen = !fullscreen">
          <JYIcon
            style="color: #FFFFFF;margin-right: 10px;"
            @click="fullscreen = !fullscreen"
            :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
          />
          <span v-text="fullscreen ? '全屏收起' : '全屏编辑'" />
        </el-button>
        <div>
          <el-button @click="choiceQuestions = false">取 消</el-button>
          <el-button type="primary" @click="addSuccess()">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="存为试卷" center top="30vh" :visible.sync="storageQuestions" width="600px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="125px" class="demo-ruleForm">
        <el-form-item label="选择试卷库" prop="paperBankId">
          <el-select filterable v-model="ruleForm.paperBankId" placeholder="请选择试卷库">
            <el-option v-for="(data, index) in libraryList" :key="index" :label="data.name" :value="data.paperBankId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="yt-flex-layout" style="justify-content: center">
        <div>
          <el-button type="primary" @click="determine" :loading="storageStatus">确 定</el-button>
          <el-button @click="storageQuestions = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import TrueOrFalse from '../../../question/list/questions/TrueOrFalse'
import Choice from '../../../question/list/questions/Choice'
import Code from '../../../question/list/questions/Code'
import ability from '@/api/ability'
import question from '@/api/question'
import paper from '@/api/paper'
import newExerciseExamApi from '@api/newExerciseExam'
import Manual from '@/components/manage/exam/exam/newPaper/Manual'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'PaperEdit',
  components: {
    YTIcon,
    TrueOrFalse,
    Choice,
    Manual,
    ConfirmDialog,
    Code
  },
  props: ['name'],
  data() {
    return {
      storageStatus: false,
      shrink: false,
      libraryList: [],
      ruleForm: {
        paperBankId: null,
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入试卷名称', trigger: 'blur' }],
        paperBankId: [{ required: true, message: '请选择试卷库', trigger: 'change' }]
      },
      storageQuestions: false,
      defaultShowNodes: [],
      selectedQuestionIds: [],
      qTypeName: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题'],
      addLoading: false,
      choiceQuestions: false,
      fullscreen: false,
      deleteLoading: false,
      saveLoading: false,
      paperInfo: [],
      drawer: false,
      selectRowId: -1,
      questionId: '',
      radioChange: '1',
      // paperId: this.$route.query.paperId,
      paperId: null,
      abilityId: '',
      questionType: null,
      addQuestionIds: [],
      delRows: [],
      removeQuestionIds: [],
      papernew: {
        paperName: '',
        questionCount: 0,
        totalPoints: 0,
        paperBankId: '',
        questionSimpleVOS: []
      },
      pageNum: 0,
      pageSize: 10,
      total: 0,
      questionAll: [], //已选用的全部题目
      questionAllid: [], //已选用的全部题目id
      questionSelection: [], //被选中题目
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      knowledgePointDownKeyword: '', //知识点筛选搜索
      knowledgePointsDown: [], //知识点筛选树
      questionPaperInfo: {}, //悬停显示使用了题目的试卷列表
      questionListLoading: false, //是否加载中
      showSize: 0, //根据屏幕宽度展示标签的个数
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      questionList: [], //已选用的题目列表
      questionLists: [], //未选用的题目列表
      difficulties: [
        { label: '简单', value: '3' },
        { label: '中等', value: '5' },
        { label: '困难', value: '7' }
      ],
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      isPackUp: true,
      isPackUps: false,
      keyword: '',
      keywords: '',
      treeData: [],
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: null }, ...this.ytConstant.questionType.getMenu()]
        }
      },
      statisticList: [],
      searchQuestionForm: {
        questionName: '',
        questionBankId: '',
        tagIds: [],
        searchForm: {
          questionType: null, //题型
          questionStatus: null, //状态
          difficultyType: null, //难度
          useCountDesc: '', //使用次数
          questionTagIds: [], //标签
          knowledgePointIds: [], //知识点
          haveKnowledge: true,
          hasKnowledge: false,
          hasTestCase: false
        }
      },
      sectionForms: []
    }
  },
  computed: {
    setAnswer() {
      return data => {
        if (data.options) {
          return data.options
            .map((option, index) => {
              if (option.correct) {
                return String.fromCharCode(index + 65)
              }
            })
            .filter(data => {
              return data
            })
            .toString()
        } else {
          return this.$replaceHtml(data.questionAnswer)
        }
      }
    }
  },
  mounted() {
    this.getBank()
  },
  methods: {
    storageQ() {
      this.ruleForm.name = this.name
      this.storageQuestions = true
    },
    //获取试卷库列表
    getBank() {
      paper.getBank().then(res => {
        this.libraryList = res.res
        res.res.forEach(res => {
          if (res.name === '默认试卷库') {
            this.ruleForm.paperBankId = res.paperBankId
          }
        })
      })
    },
    determine() {
      this.$refs['ruleForm'].validate(valid => {
        if (this.papernew.questionSimpleVOS.length) {
          let payload = {
            paperBankId: this.ruleForm.paperBankId,
            name: this.ruleForm.name,
            sectionForms: []
          }
          payload.sectionForms = this.questionList.map(res => {
            return {
              name: res.name,
              questionForms: res.questionVOS.map(item => {
                return {
                  level: 1,
                  questionId: item.id,
                  score: 1,
                  points: 1
                }
              })
            }
          })
          if (valid) {
            this.storageStatus = true
            paper
              .saveTemplate(payload)
              .then(res => {
                if (res.code === 0) {
                  this.$message({
                    message: '存储试卷成功',
                    type: 'success'
                  })
                  this.storageQuestions = false
                }
              })
              .finally(() => {
                this.storageStatus = false
              })
          }
        } else {
          this.$message.warning('题目不能为空')
        }
      })
    },
    addSuccess() {
      // 选择完题目之后
      let questionList = this.$refs['manual'].editorPageExamQuestions()
      if (Array.isArray(questionList)) {
        this.sortData(questionList)
        this.choiceQuestions = false
      }
      this.savePaper()
    },
    //批量删除弹窗
    delBatch() {
      if (this.questionSelection.length === 0) {
        return this.$message.warning('请选择删除的题目')
      }
      this.$refs['delBatchDialog'].open()
    },
    sortData(arr) {
      // 题目数据整理
      this.papernew.questionSimpleVOS.forEach(data => {
        // 过滤重复添加的题目
        let findSuffix = arr.findIndex(f => (f.id || f.questionId) === data.questionId)
        if (findSuffix !== -1) {
          arr = arr.filter(f => (f.id || f.questionId) !== (arr[findSuffix].id || arr[findSuffix].questionId))
        }
      })
      // 规范是否有新的题型分类 且该试卷有分类的  不是默认大题
      if (this.questionList.length && this.questionList[0].name !== '默认大题') {
        arr.forEach(a => {
          let find = this.questionList.findIndex(q => q.questionType === a.questionType)
          if (find === -1) {
            // 不存在  则新建
            this.questionList.push({
              level: 0,
              questionCount: 0,
              name: this.qTypeName[a.questionType],
              order: a.questionType + 1,
              questionScores: [],
              questionVOS: [],
              questionType: a.questionType,
              totalPoints: 0,
              totalScore: 0
            })
          }
        })
      }
      this.questionList.forEach(q => {
        arr.forEach(a => {
          if (this.questionList && this.questionList[0].name === '默认大题') {
            this.setRowData(a, q)
          } else {
            if (q.questionType === a.questionType) {
              this.setRowData(a, q)
            }
          }
        })
      })
      arr.forEach(a => {
        this.papernew.questionSimpleVOS.push({
          isChecked: false,
          questionId: a.id || a.questionId,
          score: 1
        })
      })
      this.questionList.forEach(q => {
        q['questionCount'] = q.questionScores.length
        q.totalScore = q.totalPoints = q.questionScores.reduce((pre, cur) => pre + cur.score, 0)
      })
      this.questionList.sort((a, b) => {
        return a.order - b.order
      })
      this.getSelectedQuestions()
    },
    setRowData(a, q) {
      q.questionScores.push({
        id: a.id,
        order: q.order,
        answer: a.answer,
        points: a.points || 0,
        codeQuestionTemplates: a.codeQuestionTemplates || null,
        questionDetail: {
          analysis: a.analysis,
          difficulty: a.difficulty,
          id: a.id,
          questionType: a.questionType,
          questionAnswer: a.questionAnswer,
          stem: a.stem,
          tags: a.tags || []
        },
        questionId: a.id,
        useCount: a.useCount,
        score: a.score || 1
      })
    },
    addQuestionsModal() {
      // 添加题目
      this.choiceQuestions = true
    },
    //保存试卷
    async savePaper() {
      let a = false
      this.sectionForms = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.sectionForms.push({
          points: item.score === 0 ? 0.5 : item.score || 1,
          questionId: item.questionId
        })
      })
      let payload = {
        paperName: this.papernew.paperName,
        examId: this.$parent.examId,
        paperId: this.$parent.paperId,
        questionPoints: this.sectionForms
      }
      await paper.updatePaper(payload).then(res => {
        if (res.code === 0) {
          this.$emit('setId', res.res)
          a = true
        }
      })
      return a
    },
    //删除该题型下的所有题目
    delQuestion(row) {
      this.delRows = row
      this.$refs['delConDialog'].open()
    },
    delQuestionRows() {
      // 确定删除
      this.questionList.forEach((item, iIndex) => {
        if (item.name === this.delRows.name) {
          this.questionList.splice(iIndex, 1)
        }
      })
      this.questionSelection = []
      this.questionSelection = this.delRows.questionScores.map(item => {
        return item.id
      })
      this.movePaper()
    },
    moveBatch() {
      this.questionSelection.forEach(qs => {
        this.questionList.forEach(q => {
          q.questionScores.forEach((s, sIndex) => {
            if (qs === s.id) {
              q.questionScores.splice(sIndex, 1)
            }
          })
        })
      })
      this.movePaper()
    },
    //批量移除题目
    movePaper() {
      this.questionSelection.forEach(d => {
        this.papernew.questionSimpleVOS.forEach((q, index) => {
          if (d === q.questionId) {
            this.papernew.questionSimpleVOS.splice(index, 1)
          }
        })
      })
      this.questionSelection = []
      this.$message.success('批量移除成功')
      // this.getPaperQuestiontree()
      // this.searchPaperSlectedQuestion()
      this.statisticalScore()
    },
    // 题目id定位当前位置
    selectorById(row) {
      this.selectRowId = row.id
      document.querySelector(`#anchor-${row.id}`).scrollIntoView({ behavior: 'smooth' })
    },
    // 获取题目信息
    questionInfo() {
      this.calculateSize(document.body.clientWidth)
      // 由于getPaperQuestionTree（）方法中的某些参数依赖于getPaperBrief()方法中的值
      // 所以必须执行同步操作的方式
      // 这里使用Promise.all()的方法来达到这种效果
      Promise.all([this.getPaperBrief(), this.getPaperQuestionTree()])
    },
    getPaperQuestionTree() {
      this.getSelectedQuestionIds()
      let payload = {
        keyword: this.keywords,
        paperId: this.$parent.examId,
        selectedQuestionIds: this.selectedQuestionIds
      }
      ability.getPaperQuestionCount(payload).then(res => {
        if (res.code === 0) {
          this.treeData = res.res
          this.defaultShowNodes.push(this.abilityId)
          this.searchPaperSlectedQuestion()
          if (this.radioChange === '2') {
            this.searchPaperNotSelectedQuestion()
          }
        }
      })
    },
    //试卷总分统计
    statisticalScore() {
      let total = 0
      this.papernew.questionSimpleVOS.forEach(item => {
        total += parseFloat(item.score === 0 ? 0.5 : item.score)
      })
      this.papernew.totalPoints = total
      this.savePaper()
    },
    setCurrentKey(id) {
      this.$nextTick(() => {
        this.$refs['abilty-tree'].setCurrentKey(id ? id : this.treeData[0].id)
      })
    },
    //设置大题分数
    setBigTopicPoint(item, index) {
      let total = 0
      let point = Math.floor(item / this.questionList[index].questionCount) === 0 ? 0.5 : Math.floor(item / this.questionList[index].questionCount)
      this.questionList[index].questionScores.forEach(q => {
        q.score = point
        total = total + point
        this.papernew.questionSimpleVOS.forEach(s => {
          if (s.questionId === q.id) {
            s.score = point
          }
        })
      })
      this.questionList[index].totalScore = total
      this.$forceUpdate()
      this.statisticalScore()
    },
    //设置小题分数
    setSmallTopicPoint(questionId, score, questionType) {
      if (this.questionList[0].name === '默认大题') {
        this.questionList[0].totalScore = 0
        this.questionList[0].questionScores.forEach(qs => {
          this.questionList[0].totalScore += Number(qs.score)
        })
      } else {
        score = Number(score)
        let total = 0
        if (score < 1 || score === '') {
          score = 0.5
        } else if (score > 1 && score.toString().indexOf('.') !== -1) {
          score = Math.floor(score)
        }
        this.questionList.forEach((q, index) => {
          if (this.ytConstant.questionType.getLabel(questionType) === q.name) {
            this.questionList[index].questionScores.forEach(s => {
              // let v = Number(s.score)
              // if (v < 1 || v) {
              //   v = 0.5
              // } else if (v > 1 && v.toString().indexOf('.') !== -1) {
              //   v = Math.floor(v)
              // }
              total = total + Number(s.score)
            })
            this.questionList[index].totalScore = total
          }
          this.$forceUpdate()
        })
      }
      this.papernew.questionSimpleVOS.forEach(item => {
        if (item.questionId === questionId) {
          item.score = score
        }
      })
      this.statisticalScore()
    },
    //移除
    toDelete(data, index, qIndex) {
      this.deleteLoading = true
      this.papernew.questionSimpleVOS.forEach((item, index) => {
        if (item.questionId === data.id) {
          this.papernew.questionSimpleVOS.splice(index, 1)
        }
      })
      this.questionList[index].questionScores.forEach((item, iIndex) => {
        if (item.id === data.id) {
          this.questionList[index].questionScores.splice(iIndex, 1)
        }
        this.questionList[index].questionAmount = this.questionList[index].questionCount = this.questionList[index].questionScores.length
      })
      this.questionList[index].questionVOS = this.questionList[index].questionScores
      this.$message.success('移除成功')
      this.setBigTopicPoint(this.questionList[index].totalScore, index)
      this.statisticalScore()
      this.deleteLoading = false
    },
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 10
      } else if (width > 1440 && width < 1920) {
        this.showSize = 15
      } else {
        this.showSize = 16
      }
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.questionSelection = this.questionAll.map(item => {
          item.isChecked = true
          return item.id
        })
      } else {
        this.questionAll.map(item => {
          item.isChecked = false
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    //单选
    handleCheckQuestion(value, questionId) {
      if (Array.isArray(value) && value.includes(undefined)) {
        return
      }
      if (value) {
        this.questionSelection.push(questionId)
      } else {
        this.questionSelection.splice(this.questionSelection.indexOf(questionId), 1)
      }
      let checkedCount = this.questionSelection.length
      this.isSelectAll = checkedCount === this.questionAll.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.questionAll.length
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    toFilterNodes(ref) {
      this.$refs[ref].filter(this.keywords)
      this.$refs[ref].setCheckedNodes([])
    },
    //获取试卷选中题目id
    getSelectedQuestionIds() {
      this.selectedQuestionIds = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.selectedQuestionIds.push(item.questionId)
      })
    },
    //根据条件查询试卷已选题目
    searchPaperSlectedQuestion() {
      this.questionListLoading = true
      this.getSelectedQuestionIds()
      // newExerciseExamApi
      //   .judgeHasPaperId(this.$parent.examId)
      paper
        .publicPaperInfo(this.$parent.paperId)
        .then(res => {
          if (res.code === 0) {
            this.questionList = res.res.sectionVOS.map(item => {
              item['questionCount'] = item.questionVOS.length
              if (item.name === '判断题') {
                item.questionType = 0
                item.questionScores = item.questionVOS.map(q => {
                  q.answer = q.questionDetail.questionAnswer === '正确'
                  return q
                })
              }
              if (item.name === '单选题') item.questionType = 1
              if (item.name === '多选题') item.questionType = 2
              if (item.name === '填空题') item.questionType = 3
              if (item.name === '简答题') item.questionType = 4
              if (item.name === '代码题') item.questionType = 5

              item.questionScores = item.questionVOS.map(t => {
                this.papernew.questionSimpleVOS.forEach(s => {
                  if (t.questionId === s.questionId) {
                    t.score = s.score
                  }
                })
                if (t.questionDetail.questionType === 1 || t.questionDetail.questionType === 2) {
                  // 单选或多选
                  t['options'] = t.questionDetail.options || []
                }
                if (t.questionDetail.questionType === 5) {
                  // 代码题
                  t['codeQuestionTemplates'] = t.questionDetail.codeQuestionTemplates || []
                }
                t['id'] = t['questionId']
                return t
              })

              item.totalScore = item.totalPoints = 0
              item.questionScores.forEach(t => {
                item.totalScore += parseFloat(t.points)
              })

              return item
            })
            this.sortPaperQuestions(this.questionList)
            this.getSelectedQuestions()
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    sortPaperQuestions(arr) {
      // 将试卷题型进行分类
      // 对象只有一条数据  且题型是默认大题的时候
      if (arr.length === 1 && arr[0].name === '默认大题') {
        let paperList = []
        const qList = arr[0].questionScores
        qList.forEach(q => {
          let find = paperList.findIndex(p => p.questionType === q.questionDetail.questionType)
          if (find === -1) {
            paperList.push({
              level: 0,
              questionCount: 0,
              name: this.qTypeName[q.questionDetail.questionType],
              order: q.questionDetail.questionType + 1,
              questionScores: [],
              questionVOS: [],
              questionType: q.questionDetail.questionType,
              totalPoints: 0,
              totalScore: 0
            })
          }
        })
        paperList.sort(function(a, b) {
          return a.order - b.order
        })
        // 每种题型添加题目
        qList.forEach(q => {
          paperList.forEach(p => {
            if (q.questionDetail.questionType === p.questionType) {
              p.questionScores.push(q)
            }
          })
        })
        // 设置每种题型总分数和总题数
        paperList.forEach(q => {
          q.questionCount = q.questionScores.length
          q.totalScore = q.totalPoints = q.questionScores.reduce((pre, cur) => {
            return (pre += cur.score)
          }, 0)
          q.questionVOS = q.questionScores
        })
        this.questionList = paperList
      }
      this.savePaper()
    },
    //获取当前技能下所有已选题目的ID
    getSelectedQuestions() {
      this.questionAll = []
      this.questionAllid = []
      this.questionList.some(item => {
        item['questionScores'].map(m => {
          this.questionAll.push({ id: m.questionId, isChecked: false })
          this.questionAllid.push(m.questionId)
        })
      })
    },
    //根据条件分页查询试卷未选题目
    searchPaperNotSelectedQuestion() {
      this.getSelectedQuestionIds()
      this.questionListLoading = true
      let hasKnowledges = ''
      if (this.searchQuestionForm.searchForm.hasKnowledge && !this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = false
      } else if (!this.searchQuestionForm.searchForm.hasKnowledge && this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = true
      }
      let payload = {
        abilityId: this.abilityId,
        difficultyType: this.searchQuestionForm.searchForm.difficultyType,
        hasKnowledge: hasKnowledges,
        keyword: this.keyword,
        knowledgePointIds: this.searchQuestionForm.searchForm.knowledgePointIds,
        questionStatus: this.searchQuestionForm.searchForm.questionStatus,
        questionTagIds: this.searchQuestionForm.searchForm.questionTagIds,
        questionType: this.searchQuestionForm.searchForm.questionType,
        selectedQuestions: this.selectedQuestionIds,
        useCountDesc: this.searchQuestionForm.searchForm.useCountDesc
      }
      question
        .searchPaperNotSlectedQuestion(this.pageNum, this.pageSize, payload)
        .then(res => {
          if (res.code === 0) {
            this.questionLists = res.res.data
            this.total = res.res.total
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    //获取试卷信息
    getPaperBrief() {
      paper.getPaperBrief(this.$parent.paperId).then(res => {
        if (res.code === 0) {
          this.papernew = res.res
          let questionSimple = []
          if (this.papernew.questionSimpleVOS) {
            this.papernew.questionSimpleVOS.forEach(item => {
              questionSimple.push({ ...item, isChecked: false })
            })
          }
          this.papernew.questionSimpleVOS = questionSimple
        }
      })
    },
    reSearchQuestions() {
      this.pageNum = 0
      this.total = 0
      this.questionAll = []
      this.questionAllid = []
      this.searchPaperNotSelectedQuestion()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/filterDropdownMenu';
.yt-main {
  position: relative;
  .wh();
  text-align: left;
  padding-top: 10px;
  background: #f8f8f8;
}
.yt-container {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.yt-right--navigation {
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 20px;
  background: #fff;
  margin-left: 10px;
  display: flex;
  justify-content: flex-start;
  //width: 270px;
  svg {
    height: 100%;
    align-items: center;
  }
  .header {
    height: 50px;
    .font(14px, bold, #282c3d);
    align-items: center;
    padding-left: 20px;
    span {
      .font(14px, bold, #448bff);
    }
  }
  .hr-style {
    border: 1px solid #e2e4e8;
  }
  .question-type {
    padding: 10px 20px 0 20px;
    .q-style {
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    .type {
      width: 3px;
      height: 10px;
      background: #448bff;
      opacity: 1;
      border-radius: 2px;
      /*margin-top: 8px;*/
      margin-right: 5px;
    }
    .question-number {
      flex-wrap: wrap;
      .number {
        border-radius: 3px;
        .wh(24px, 24px);
        border: 1px solid #d9d9d9;
        margin: 10px 12px 0 0;
        &:hover {
          cursor: pointer;
        }
      }
      .selectRow {
        background: #448bff;
        border: 0;
        color: #fff;
      }
    }
  }
}
.yt-content {
  background-color: #f3f4f7;
  padding: 0;
  box-shadow: none;
}
.yt-content-top {
  padding: 10px 20px;
  margin-bottom: 10px;
  height: 55px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  .question-order {
    white-space: nowrap;
    height: 32px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    ::v-deep .el-input__inner {
      height: 32px;
      color: #000000;
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      line-height: 22px;
    }
  }
  .question-news {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    span {
      margin-right: 40px;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      opacity: 1;
    }
  }
}
//筛选列表
.filter-container {
  background: #ffffff;
  padding: 16px 20px 0;
  margin-bottom: 10px;
  position: relative;
  .input-container {
    position: absolute;
    width: 334px;
    right: 125px;
    top: 7px;
    ::v-deep .el-input__inner {
      height: 38px;
    }
  }
  .pack-up {
    position: absolute;
    width: 53px !important;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    right: 25px;
    top: 13px;
    .flexStyle(flex, flex-start, center);
    p {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      user-select: none; //不被选中
    }

    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 20px;
    margin-bottom: 16px;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: @small;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
    }
    .item {
      height: 20px;
      line-height: 20px;
      margin: 0 8px;
      padding: 0 15px;
      font-size: @small;
      cursor: pointer;
    }
    .default-item {
      &:hover {
        border-radius: 11px;
        background: #448bff;
        color: #ffffff;
      }
    }
    .active {
      border-radius: 11px;
      background: #448bff;
      color: #ffffff;
    }
    .checkbox-container {
      .flexStyle(flex, flex-start, center);
      border-left: 1px solid #d8d8d8;
      margin-left: 17px;
      .el-checkbox {
        margin-left: 40px;
        margin-right: 0;
        ::v-deep .el-checkbox__label {
          font-size: @small;
          padding-left: 6px;
          color: #000000 !important;
        }
      }
    }
    .other-item {
      padding: 0;
      margin: 0 22px 0 8px;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      user-select: none; //禁止双击选中文字
      svg {
        color: #a6a6a6;
      }
    }
    .asc {
      transform: rotate(180deg);
    }
    .select {
      font-size: @small;
      &:hover {
        color: #448bff;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .el-dropdown {
      .flexStyle(flex, flex-start, center);
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
    .el-icon-arrow-down {
      font-size: @small;
      color: #999999 !important;
      transition: all 0.2s ease-in-out;
    }
  }
  .statistic-question {
    height: 65px;
    .question-border {
      .wh(170px, 60px);
      border: 1px solid #e2e4e8;
      border-radius: 6px;
      .img {
        width: 50px;
      }
      .content {
        flex-direction: column;
        justify-content: space-around;
        width: calc(100% - 50px);
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
//全选
.select-all {
  padding-left: 20px;
  height: 55px;
  .flexStyle(flex, space-between, center);
  background: #ffffff;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  .btn-style {
    margin-right: 40px;
    span {
      margin-left: 8px;
    }
  }
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  height: calc(100% - 55px);
  overflow-y: auto;
  .list {
    background-color: #f8f8f8;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      margin-left: 20px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header,
    .body,
    .footer {
      padding-left: 20px;
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    //题目部分
    .body {
      font-size: @small;
      width: 97%;
      .stem {
        display: flex;
        .info {
          width: 97%;
          overflow-wrap: break-word;
        }
      }
      .stem-code {
        height: 200px;
        margin-right: 40px;
        margin-top: 15px;
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px dotted #e9e9e9;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
        }
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      .el-input {
        max-width: 60px;
        ::v-deep .el-input__inner {
          height: 20px;
          border: 1px solid #d9d9d9;
        }
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #448bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
.question-sort {
  width: 100%;
  height: 50px;
  background: #f0f0f0;
  opacity: 1;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  padding: 15px 0px 15px 20px;
  p {
    font-size: 14px;
    line-height: 22px;
    color: #282c3d;
    opacity: 1;
    margin-right: 5px;
  }
  .el-input {
    width: 70px;
    ::v-deep .el-input__inner {
      height: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      text-align: center;
    }
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
.btn-del:hover,
.del-svg:hover {
  svg {
    fill: #448bff !important;
  }
}
::v-deep .yt-container-flex .yt-content {
  width: calc(100% - 10px - 280px);
}
::v-deep .yt-paper--body {
  height: auto !important;
}
::v-deep .el-input__inner {
  height: 20px;
  border: 1px solid #d9d9d9;
  text-align: center;
}
.delModal {
  ::v-deep .el-dialog__body .footer {
    margin-bottom: 20px !important;
  }
  ::v-deep .el-dialog__header {
    line-height: 63px !important;
  }
}
.demo-ruleForm {
  ::v-deep .el-input__inner {
    height: 38px;
    text-align: left;
    width: 350px;
  }
  ::v-deep .el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
@media screen and (max-height: 936px) {
  ::v-deep .el-dialog {
    margin-top: 17vh !important;
  }
}
</style>
