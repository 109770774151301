<template>
  <div class="auto-container" :style="{ height: !fullscreen ? '550px' : '751px' }">
    <div class="auto-left">
      <div class="yt-container left-has-menu" style="background-color: #ffffff">
        <div class="yt-content-tree">
          <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
            <el-menu-item index="1">
              <div class="icon" :style="{ background: activeIndex === '1' ? '#448bff' : '' }">
                <YTIcon
                  :href="'#icon-lingdaitie3'"
                  :style="{ background: activeIndex === '1' ? '#448bff' : '', fill: activeIndex === '1' ? '#FFFFFF' : '' }"
                />
              </div>
              <span>按专业选择</span>
            </el-menu-item>
            <el-menu-item index="2">
              <div class="icon" :style="{ background: activeIndex === '2' ? '#448bff' : '' }">
                <YTIcon
                  :href="'#icon-biaoqian'"
                  :style="{ background: activeIndex === '2' ? '#448bff' : '', fill: activeIndex === '2' ? '#FFFFFF' : '' }"
                />
              </div>
              <span>按技能选择</span>
            </el-menu-item>
          </el-menu>
          <el-input v-model="keyword" class="yt-search search" placeholder="通过技能名称筛选" @keydown.enter.native="toFilterNode('knowledge-tree')">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode('knowledge-tree')"></i>
          </el-input>
          <div class="tree has-tool" :style="{ height: !fullscreen ? '436px' : '637px' }">
            <el-tree
              :data="treeData"
              :filter-node-method="filterNode"
              node-key="id"
              ref="knowledge-tree"
              :props="{ label: 'name', children: 'abilityTreeVOS' }"
              :highlight-current="true"
              @node-click="handleClickTreeNode"
            >
              <div class="tree-node" slot-scope="{ node, data }">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
                <div style="margin-right: 12px">{{ data.questionCount }}</div>
              </div>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="auto-right">
      <div class="generation-container">
        <div class="ability-form" ref="abilityForm_area">
          <div class="ability-header">
            <p>
              技能名称:
              <el-tooltip effect="dark" :content="activeAbility.name" placement="top-start">
                <span>{{ activeAbility.name }}</span>
              </el-tooltip>
            </p>
            <p style="margin-left: 105px">
              总题目数量:
              <span style="color: #448BFF">{{ questionTypeNum.totalNum }}</span>
            </p>
          </div>
          <el-form ref="form" :rules="ruleValidate" :model="abilityForm" class="yt-form" label-width="106px">
            <el-form-item label="技能难度" prop="difficulty">
              <el-select v-model="abilityForm.difficulty" placeholder="请选择" @change="changeQuestionTypeNum">
                <el-option v-for="item in this.ytConstant.difficultType.getMenu()" :key="item.value" :value="item.value" :label="item.label">{{
                  item.label
                }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择题目方式">
              <el-radio-group v-model="abilityForm.mode">
                <!--                <el-radio :label="1">算法自动选择</el-radio>-->
                <el-radio :label="2">设置题型数量</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="abilityForm.mode === 2">
              <el-form-item label="判断题" prop="trueOrFalse">
                <el-input-number v-model="abilityForm.trueOrFalse" :min="0"></el-input-number>
                <!-- <el-input v-model="abilityForm.trueOrFalse" oninput="this.value = this.value.replace(/[^0-9]/g, '')" /> -->
                <span>{{ questionTypeNum.trueOrFalseNum }}</span>
                <el-tooltip effect="dark" content="判断题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
              <el-form-item label="单选题" prop="singleChoice">
                <!-- <el-input v-model="abilityForm.singleChoice" oninput="this.value = this.value.replace(/[^0-9]/g, '')" /> -->
                <el-input-number v-model="abilityForm.singleChoice" :min="0"></el-input-number>
                <span>{{ questionTypeNum.singleChoiceNum }}</span>
                <el-tooltip effect="dark" content="单选题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
              <el-form-item label="多选题" prop="multipleChoice">
                <el-input-number v-model="abilityForm.multipleChoice" :min="0"></el-input-number>
                <span>{{ questionTypeNum.multipleChoiceNum }}</span>
                <el-tooltip effect="dark" content="多选题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
              <el-form-item label="填空题" prop="fillInBlank">
                <el-input-number v-model="abilityForm.fillInBlank" :min="0"></el-input-number>
                <span>{{ questionTypeNum.fillInBlankNum }}</span>
                <el-tooltip effect="dark" content="填空题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
              <el-form-item label="简答题" prop="subjectiveNum">
                <el-input-number v-model="abilityForm.subjectiveNum" :min="0"></el-input-number>
                <span>{{ questionTypeNum.subjectiveNum }}</span>
                <el-tooltip effect="dark" content="简答题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
              <el-form-item label="代码题" prop="code">
                <el-input-number v-model="abilityForm.code" :min="0"></el-input-number>
                <span>{{ questionTypeNum.codeNum }}</span>
                <el-tooltip effect="dark" content="代码题总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="题目数">
                <el-input v-model="abilityForm.questionCount" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
                <span>{{ questionTypeNum.totalNum }}</span>
                <el-tooltip effect="dark" content="题目总数" placement="top">
                  <YTIcon :href="'#icon-tishi'" />
                </el-tooltip>
              </el-form-item>
            </template>
          </el-form>
          <div class="confirm-button">
            <el-button size="small" type="primary" @click="addAbility">确定技能设置</el-button>
          </div>
        </div>
      </div>
      <div class="ability">
        <div class="ability-header">
          <p>已选技能</p>
          <p>
            已选题目:
            <span style="color: #FF5050">{{ testList.questionCount }}</span>
          </p>
        </div>
        <ul class="ability-selection">
          <li v-for="(item, index) in abilitySelection" :key="index" class="item">
            <div class="fun">
              <el-tooltip effect="dark" :content="item.name" placement="top-start">
                <p>{{ item.name }}</p>
              </el-tooltip>
              <YTIcon :href="'#icon-shanchu'" @click="delAbility(index, item)" />
            </div>
            <div class="info">
              <span>难度: {{ ytConstant.difficultType.getLabel(item.difficulty) }}</span>
              <span>预选题: {{ item.count }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import post from '@api/post'
import question from '@api/question'
import ability from '@api/ability'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'AutoPaper',
  props: ['state', 'fullscreen'],
  components: { YTIcon },
  data() {
    const checkTrueOrFalse = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.trueOrFalseNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    const checkSingleChoice = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.singleChoiceNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    const checkMultipleChoice = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.multipleChoiceNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    const checkFillInBlank = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.fillInBlankNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    const checkSubjectiveNum = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.subjectiveNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    const checkCode = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目不能为空'))
      }
      if (value > this.questionTypeNum.codeNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        callback()
      }
    }
    return {
      abilityFormHeight: 0,
      activeIndex: '1',
      treeData: [],
      treeProps: {},
      keyword: '',
      isPackUp: false,
      activeAbility: {
        name: '',
        description: '',
        questionCount: 0
      },
      ruleValidate: {
        difficulty: [{ required: true, message: '请选择技能难度', trigger: 'change' }],
        trueOrFalse: [{ validator: checkTrueOrFalse, type: 'number', trigger: 'change' }],
        singleChoice: [{ validator: checkSingleChoice, type: 'number', trigger: 'change' }],
        multipleChoice: [{ validator: checkMultipleChoice, type: 'number', trigger: 'change' }],
        fillInBlank: [{ validator: checkFillInBlank, type: 'number', trigger: 'change' }],
        subjectiveNum: [{ validator: checkSubjectiveNum, type: 'number', trigger: 'change' }],
        code: [{ validator: checkCode, type: 'number', trigger: 'change' }]
      },
      knowledgeList: [],
      knowledgeSelection: [],
      abilityForm: {
        difficulty: this.ytConstant.difficultType.SIMPLE_MAX,
        mode: 2,
        trueOrFalse: 0,
        singleChoice: 0,
        multipleChoice: 0,
        fillInBlank: 0,
        subjectiveNum: 0,
        code: 0,
        questionCount: 0
      },
      abilitySelection: [],
      testList: {
        questionCount: 0
      },
      questionTypeNum: {
        codeNum: 0,
        fillInBlankNum: 0,
        multipleChoiceNum: 0,
        singleChoiceNum: 0,
        subjectiveNum: 0,
        totalNum: 0,
        trueOrFalseNum: 0
      }
    }
  },
  computed: {
    ...mapGetters('question', ['getEditorData']),
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    }
  },
  created() {
    this.getCategoryList()
  },
  methods: {
    ...mapMutations('question', ['handleSelectAll', 'setEditorData']),
    selectMenu(index) {
      this.activeIndex = index
      this.treeData = []
      // this.resetAbilityForm()
      this.resetMenu()
      if (this.activeIndex === '1') {
        this.getCategoryList()
      } else {
        this.getAbilityQuestionList()
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    toFilterNode(ref) {
      this.$refs[ref].filter(this.keyword)
      this.$refs[ref].setCheckedNodes([])
    },
    handleClickTreeNode(data) {
      if (data.hasOwnProperty('parentId')) {
        this.knowledgeSelection = []
        this.activeAbility = data
        this.resetAbilityForm()
        this.getKnowledgeSimple()
      }
    },
    getKnowledgeSimple() {
      ability.getKnowledgeSimple(this.activeAbility.id).then(res => {
        this.knowledgeList = res.res
        for (let i = 0; i < this.knowledgeList.length; i++) {
          this.knowledgeSelection.push(this.knowledgeList[i].knowledgeId)
        }
        this.getQuestionTypeCount()
      })
    },
    getQuestionTypeCount() {
      question.getQuestionTypeCount(this.knowledgeSelection).then(res => {
        this.questionTypeNum = res.res
      })
    },
    changeQuestionTypeNum(value) {
      question.getKnowledgeCount(this.knowledgeSelection, value).then(res => {
        this.questionTypeNum = res.res
      })
    },
    selectKnowledge(id) {
      if (this.knowledgeSelection.includes(id)) {
        this.knowledgeSelection.splice(this.knowledgeSelection.indexOf(id), 1)
      } else {
        this.knowledgeSelection.push(id)
      }
      this.changeQuestionTypeNum(this.abilityForm.difficulty)
    },
    addAbility() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const abilityNum =
            parseInt(this.abilityForm.trueOrFalse) +
            parseInt(this.abilityForm.singleChoice) +
            parseInt(this.abilityForm.multipleChoice) +
            parseInt(this.abilityForm.fillInBlank) +
            parseInt(this.abilityForm.subjectiveNum) +
            parseInt(this.abilityForm.code)
          if (this.abilityForm.mode === 1) {
            if (this.abilityForm.questionCount <= 0 || !this.activeAbility.name) {
              this.$message.warning('请填写题目数量')
              return
            }
            this.abilitySelection.unshift({
              name: this.activeAbility.name,
              difficulty: this.abilityForm.difficulty,
              count: this.abilityForm.questionCount
            })
            this.testList.questionCount = this.testList.questionCount + this.abilityForm.questionCount
          } else {
            if (abilityNum <= 0 || !this.activeAbility.name) {
              this.$message.warning('请填写题目数量')
              return
            }
            this.abilitySelection.forEach((item, index) => {
              if (this.activeAbility.id === item.abilityId && this.abilityForm.difficulty === item.difficulty) {
                this.testList.questionCount = this.testList.questionCount - item.count
                this.abilitySelection.splice(index, 1)
              }
            })
            this.abilitySelection.unshift({
              name: this.activeAbility.name,
              abilityId: this.activeAbility.id,
              difficulty: this.abilityForm.difficulty,
              knowledgeIds: this.knowledgeSelection,
              typeQuestionCount: {
                '0': this.abilityForm.trueOrFalse,
                '1': this.abilityForm.singleChoice,
                '2': this.abilityForm.multipleChoice,
                '3': this.abilityForm.fillInBlank,
                '4': this.abilityForm.subjectiveNum,
                '5': this.abilityForm.code
              },
              count: abilityNum
            })
            this.testList.questionCount = this.testList.questionCount + abilityNum
            this.resetAbilityForm()
          }
        }
      })
    },
    delAbility(index, item) {
      this.testList.questionCount = this.testList.questionCount - item.count
      this.abilitySelection.splice(index, 1)
    },
    resetAbilityForm() {
      this.$refs.form.resetFields()
      this.abilityForm.mode = 2
    },
    createTest() {
      if (this.testList.questionCount < 1) {
        this.$message.warning('请添加题目')
        return
      }
      question.autoCreateExam(this.abilitySelection).then(res => {
        if (res.code === 0) {
          this.$message.success('自动出卷成功')
          this.$store.commit('question/reset')
          this.handleSelectAll({
            questionList: res.res,
            isClassify: false,
            currentI: this.getEditorData.currentIndex
          })
          this.abilitySelection = []
          this.$router.push({
            path: '/exam/preViewExam',
            query: {
              bankId: this.$route.query.bankId,
              IsAuto: true
            }
          })
        }
      })
    },
    resetMenu() {
      this.activeAbility.name = ''
      this.activeAbility.description = ''
      this.activeAbility.questionCount = 0
      this.questionTypeNum.codeNum = 0
      this.questionTypeNum.fillInBlankNum = 0
      this.questionTypeNum.multipleChoiceNum = 0
      this.questionTypeNum.singleChoiceNum = 0
      this.questionTypeNum.subjectiveNum = 0
      this.questionTypeNum.totalNum = 0
      this.questionTypeNum.trueOrFalseNum = 0
    },
    getCategoryList() {
      post.getCategoryListTree().then(res => {
        this.treeData = res.res
      })
    },
    getAbilityQuestionList() {
      ability.getAbilityQuestionCount().then(res => {
        this.treeData = res.res
      })
    }
  }
  // mounted() {
  //   this.getabilityFormHeight()
  // }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables.less';
.auto-container {
  .flexStyle(flex, center, center);
  // height: calc(100vh - 187px);
  .auto-left {
    width: 30%;
    min-width: 25%;
    height: 100%;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    .yt-container {
      padding: 0;
      background-color: transparent;
    }
    .yt-search {
      width: 91%;
      height: 32px;
      margin-top: 13px;
    }
    .tree-node {
      width: 100%;
      .flexStyle(flex, space-between, center);
    }
    ::v-deep .el-tree-node__content {
      padding: 0px 20px !important;
    }
    ::v-deep .el-tooltip {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 168px;
      text-align: left;
    }
    .tree {
      // height: calc(100vh - 301px);
      overflow: auto;
      margin-top: 16px;
    }
    .yt-menu {
      margin-left: 0 !important;
      .flexStyle(flex, center, center);
      border-bottom: 1px solid #e2e4e8 !important;
      li {
        .flexStyle(flex, center, center);
        &:hover {
          .icon {
            background: #448bff;
          }
        }
      }
      span {
        margin-left: 6px;
      }
      .icon {
        width: 20px;
        height: 20px;
        background: #dbdbdb;
        border-radius: 50%;
        .flexStyle(flex, center, center);
        svg {
          fill: #ffffff;
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  .auto-right {
    width: 100%;
    margin-left: 10px;
    height: 100%;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    .flexStyle(flex, space-between, flex-start);
    .generation-container {
      .flexStyle(flex, center, flex-start);
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      padding: 20px 10px;
      border-right: 1px solid #e2e4e8;
      overflow: auto;
      .ability-header {
        .flexStyle(flex, flex-start, flex-start);
        margin-left: 9px;
        position: relative;
        margin-bottom: 30px;
        p {
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #000000;
        }
        p:before {
          content: '';
          position: absolute;
          width: 3px;
          height: 12px;
          background: #448bff;
          border-radius: 3px;
          top: 25%;
          margin-left: -6px;
        }
      }
      ::v-deep .el-radio-group {
        width: 100%;
        text-align: left;
      }
      .confirm-button {
        margin-top: 30px;
      }
      .yt-menu {
        border-bottom: solid 1px #e6e6e6;
        margin-left: 0;
        .flexStyle(flex, center, center);
      }
    }
    .yt-form {
      svg {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #448bff;
        margin-left: 6px;
      }
      ::v-deep .el-input-number {
        width: 350px;
      }
      ::v-deep .el-form-item {
        margin-bottom: 38px;
      }
      ::v-deep .el-input-number .el-input__inner {
        padding-left: 50px;
      }
    }

    ::v-deep .el-form-item__content {
      text-align: left;
    }
    .ability {
      width: 30%;
      padding: 20px 10px 20px 20px;
      .ability-header {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        p:before {
          content: '';
          position: absolute;
          width: 3px;
          height: 12px;
          background: #ff5050;
          border-radius: 3px;
          top: 25%;
          margin-left: -6px;
        }
        .flexStyle(flex, space-between, flex-start);
      }
      .ability-selection {
        margin-top: 20px;
        .item {
          border-bottom: solid 1px #e2e4e8;
          .fun {
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #000000;
            .flexStyle(flex, space-between, flex-start);
            padding-bottom: 4px;
            padding-top: 4px;
          }
          .info {
            margin-bottom: 10px;
            .flexStyle(flex, space-between, flex-start);
          }
        }
      }
    }
  }
}
</style>
