import config from '@/config/config'
import { axios } from '@/api/index'

const newExerciseExamUrl = config.baseUrl + '/exam/api/v2'

export default {
  judgeHasPaperId(examId) {
    // 判断是否存在试卷
    return axios.get(`${newExerciseExamUrl}/exercise/exam/paper/get?examId=${examId}`)
  }
}
