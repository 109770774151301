<template>
  <div class="exam-container">
    <div class="exam-top">
      <div class="exam-select">
        <template v-for="(data, key, index) in selectType">
          <div :key="index" class="exam-type">
            <span>{{ data.param }}</span>
            <div v-if="data.type === 'select'">
              <el-select class="select" v-model="data.value" :clearable="true" style="margin-left: 9px;width: 370px;" :placeholder="data.placeholder">
                <!--                <template v-if="key === 'postId'">-->
                <!--                  <el-option v-for="(item, index) in data.data" :value="item.id" :key="index" :label="item.name" />-->
                <!--                </template>-->
                <template v-if="key === 'departmentId'">
                  <el-option v-for="(item, index) in data.data" :value="item.departmentId" :key="index" :label="item.name" />
                </template>
                <template v-else>
                  <el-option v-for="(item, index) in data.data" :value="item.tagId" :key="index" :label="item.name" />
                </template>
              </el-select>
            </div>
            <div v-else-if="data.type === 'cascader'">
              <el-cascader
                append-to-body
                clearable
                @change="cascadeChange"
                style="width: 370px;margin-left: 9px;"
                size="small"
                :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                :options="postData"
              />
            </div>
            <div v-else>
              <el-input :placeholder="data.placeholder" style="margin-left: 9px;width: 370px;" v-model="data.value"></el-input>
            </div>
          </div>
        </template>
      </div>
      <el-button class="el-button" type="primary" style="width: 80px;" size="small" @click="getSearch">查询</el-button>
    </div>
    <div class="exam-middle">
      <p></p>
      <div class="font">
        已选择考试人数：<span>{{ numberList.length }}人</span>
      </div>
      <div v-if="limitPayload" class="limit_tip">
        <span>剩余人次数:</span>
        <span class="blue_span">{{ limitPayload.unusedCount }}</span>
      </div>
    </div>
    <div class="staff-table yt-table">
      <el-table :data="tableData" ref="multipleTable" max-height="260" :row-key="getRowKey" @selection-change="rowChange" style="width: 100%">
        <el-table-column type="selection" :reserve-selection="true" width="30" />
        <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
        <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
        <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
        <el-table-column label="班级" prop="departmentVOS" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.departmentVOS[0].name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="专业" prop="postVO" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.postVO.postName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        :total="total"
        :current="pageNum + 1"
        :page-size-opts="[10, 20, 40, 100]"
        :page-size="pageSize"
        @on-change="changePage"
        @on-page-size-change="changePageSize"
        show-elevator
        show-sizer
        show-total
      />
    </div>
  </div>
</template>

<script>
import newStaffApi from '@/api/newStaff'
import newExamManageApi from '@/api/newExamManage'
import postApi from '@/api/post'
import departmentApi from '@/api/department'
import tagApi from '@api/tag'
import functionApi from '@api/functionApi'
export default {
  name: 'AddExam',
  props: ['checkIn', 'examId', 'limitPayload'],
  data() {
    return {
      tableData: [],
      postData: null,
      selectedStaffs: [],
      numberList: [],
      total: 0,
      pageNum: 0,
      pageSize: 10,
      selectType: {
        postId: { type: 'cascader', value: '', placeholder: '请选择专业', data: [], param: '专业' }, //岗位
        departmentId: { type: 'select', value: '', placeholder: '请选择班级', data: [], param: '班级' }, //部门
        tabId: { type: 'select', value: '', data: [], placeholder: '请选择标签', param: '标签' }, //标签
        keyword: { type: 'input', value: '', placeholder: '请输入姓名', param: '姓名' } //姓名
      },
      responseForm: {
        sort: 'asc',
        page: 0,
        size: 10,
        status: 0,
        examId: null
        // departmentId: 0,
        // keyword: '',
        // postId: 0,
        // tabId: 0
      }
    }
  },
  methods: {
    getStaffList() {
      this.getTab()
      this.getPostData()
      this.getPost()
      this.getDepartment()
      this.responseForm.examId = this.$parent.examId || Number(this.$route.query['examId'])
      newStaffApi.getStaffSearch(this.responseForm).then(res => {
        if (res.code === 0) {
          this.tableData = [...res.res.data]
          this.total = res.res.total
        }
      })
      this.validateStaffsSelected()
    },
    cascadeChange(data) {
      this.selectType.postId.value = data.length ? data[data.length - 1] : ''
    },
    getPostData() {
      functionApi.getAllPostById(0).then(res => {
        let arr = JSON.stringify(res.res)
        this.postData = JSON.parse(
          arr
            .replace(/posts/g, 'children')
            .replace(/id/g, 'value')
            .replace(/name/g, 'label')
        )
      })
    },
    validateStaffsSelected() {
      const map = new Map()
      let payload = {
        sort: 'asc',
        page: 0,
        size: 999999999,
        status: 0,
        examId: this.examId || this.$parent.examId || Number(this.$route.query['examId'])
      }
      newStaffApi.getStaffSearch(payload).then(res => {
        if (res.code === 0) {
          res.res.data.forEach(t => {
            if (t.examStatus === 0) {
              this.$refs.multipleTable.toggleRowSelection(t, true)
              this.numberList.push(t)
            }
          })
          this.numberList = this.numberList.filter(arr => !map.has(arr.userId) && map.set(arr.userId, 1)) //数组对象去重
        }
      })
    },
    getTab() {
      let payload = {
        typeId: 4
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.selectType.tabId.data = [...res.res]
        }
      })
    },
    getPost() {
      postApi.getAllPosts().then(res => {
        this.selectType.postId.data = [...res.res]
      })
    },
    getDepartment() {
      departmentApi.getdepartmentAll().then(res => {
        this.selectType.departmentId.data = [...res.res]
      })
    },
    getSearch() {
      Object.keys(this.selectType).forEach(key => {
        if (this.selectType[key].value) {
          Object.defineProperty(this.responseForm, key, { value: this.selectType[key].value, writable: true, enumerable: true, configurable: true })
        } else {
          this.responseForm.hasOwnProperty(key) ? delete this.responseForm[key] : false
        }
      })
      this.responseForm.page = 0
      this.getStaffList()
    },
    changePage(page) {
      this.responseForm.page = page - 1
      this.getStaffList()
    },
    changePageSize(Size) {
      this.responseForm.size = Size
      this.getStaffList()
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.userId
    },
    rowChange(selection) {
      const res = new Map()
      this.numberList = selection.filter(arr => !res.has(arr.userId) && res.set(arr.userId, 1)) //数组对象去重
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables.less';
.exam-container {
  .exam-top {
    display: flex;
    justify-content: space-evenly;
    .exam-select {
      .flexStyle(flex, center, center);
      width: 84%;
      flex-wrap: wrap;
      .exam-type {
        .flexStyle(flex, center, center);
        margin: 0 40px 20px 0;
        &:nth-child(2),
        &:nth-child(4) {
          margin-right: 0;
        }
      }
    }
  }
  .exam-middle {
    transition: font-size 0s linear 0s;
    .flexStyle(flex, flex-start, center);
    .limit_tip {
      margin-left: 20px;
      .blue_span {
        margin-left: 10px;
        color: #448bff;
      }
    }
    p {
      width: 3px;
      height: 12px;
      background: #448bff;
      border-radius: 3px;
      margin-right: 4px;
    }
    .font {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
      span {
        color: #448bff;
      }
    }
  }
  .staff-table {
    margin-top: 10px;
  }
}
::v-deep .el-input__inner {
  line-height: 40px;
  height: 40px;
}
::v-deep .el-input__icon {
  line-height: 40px;
}
</style>
