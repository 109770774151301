<template>
  <div class="finish-style yt-flex-layout">
    <div class="yt-flex-layout">
      <div class="info-style">
        <span class="form-title">考试名称:</span>
        <span style="margin-left: 8px" />
        <span>{{ examInfo[0].value }}</span>
      </div>
      <div class="info-style">
        <span class="form-title">专业:</span>
        <span style="margin-left: 8px" />
        <span class="post-style" v-for="(v, vIndex) in examInfo[1].value" :key="vIndex">{{ v }}</span>
      </div>
    </div>
    <div class="yt-flex-layout">
      <div class="info-style">
        <span class="form-title">考试时长:</span>
        <span style="margin-left: 8px" />
        <span>{{ examInfo[2].value }}</span>
      </div>
      <div class="info-style">
        <span class="form-title">通过比例:</span>
        <span style="margin-left: 8px" />
        <span>{{ examInfo[3].value }}</span>
      </div>
    </div>
    <div class="yt-flex-layout">
      <div class="info-style">
        <span class="form-title">开始时间:</span>
        <span style="margin-left: 8px" />
        <span>{{ $formatTime(examInfo[4].value, 'yyyy-MM-dd hh:mm:ss') }}</span>
      </div>
      <div class="info-style">
        <span class="form-title">结束时间:</span>
        <span style="margin-left: 8px" />
        <span>{{ $formatTime(examInfo[5].value, 'yyyy-MM-dd hh:mm:ss') }}</span>
      </div>
    </div>
    <div class="yt-flex-layout info-style" style="width: 100%">
      <span class="form-title">考试简介:</span>
      <span style="margin-left: 8px" />
      <span>{{ examInfo[6].value }}</span>
    </div>
    <div class="yt-flex-layout info-style" style="width: 100%">
      <span class="form-title">考前须知:</span>
      <span style="margin-left: 8px" />
      <span>{{ examInfo[7].value }}</span>
    </div>
    <div class="yt-flex-layout info-style" style="width: 100%">
      <span class="form-title">考后须知:</span>
      <span style="margin-left: 8px" />
      <span>{{ examInfo[8].value }}</span>
    </div>
    <div class="yt-flex-layout btn-div">
      <el-button class="btn-style" @click="open(0)" v-if="paperType !== '4'">预览试卷</el-button>
      <el-button class="btn-style" @click="open(1)">查看考试人员</el-button>
    </div>
    <el-dialog destroy-on-close :visible.sync="staffModal" top="15vh" width="45%">
      <div slot="title" class="modalTitle">查看考试人员</div>
      <div class="content yt-table">
        <el-table stripe size="small" :data="tableData" max-height="400">
          <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
          <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
          <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
          <el-table-column v-if="!staffValue" label="专业" prop="postName" show-overflow-tooltip />
          <el-table-column v-if="!staffValue" label="班级" prop="departmentName" show-overflow-tooltip />
          <el-table-column v-if="staffValue" label="邮件状态" prop="sendStatus">
            <template slot-scope="scope">
              <span>{{ scope.row.sendStatus ? '已发' : '未发' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="yt-flex-layout" style="justify-content: center">
        <el-button type="primary" class="btn yt-flex-layout flexCenter" @click="staffModal = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog class="paperView" destroy-on-close :visible.sync="paperModal" lock-scroll width="1080px" :fullscreen="fullscreen">
      <div slot="title" class="modalTitle">预览试卷</div>
      <div class="content yt-table">
        <PreviewPaper :fullscreen="fullscreen" ref="editor" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="first-button" type="primary" @click="fullscreen = !fullscreen">
          <JYIcon
            @click="fullscreen = !fullscreen"
            style="color: #FFFFFF;margin-right: 16px;"
            :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
          />
          <span v-text="fullscreen ? '全屏收起' : '全屏显示'" />
        </el-button>
        <div>
          <el-button type="primary" @click="paperModal = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import newExamManageApi from '@api/newExamManage'
import externalEmployeesApi from '@api/externalEmployees'
import JYIcon from '@components/common/JYIcon'
import PreviewPaper from '@components/manage/exam/exam/newPaper/PreviewPaper'
export default {
  name: 'finishPage',
  components: { JYIcon, PreviewPaper },
  data() {
    return {
      fullscreen: false,
      examInfo: [
        {
          name: '考试名称',
          key: 'name',
          value: '--'
        },
        {
          name: '岗位',
          key: 'postNames',
          value: []
        },
        {
          name: '考试时长',
          key: 'limitTime',
          value: '--'
        },
        {
          name: '通过比例',
          key: 'passScore',
          value: '--'
        },
        {
          name: '开始时间',
          key: 'startTime',
          value: null
        },
        {
          name: '结束时间',
          key: 'endTime',
          value: null
        },
        {
          name: '考试简介',
          key: 'description',
          value: '--'
        },
        {
          name: '考前须知',
          key: 'messageBeforeExam',
          value: '--'
        },
        {
          name: '考后须知',
          key: 'messageAfterExam',
          value: '--'
        }
      ],
      staffModal: false,
      paperModal: false,
      staffValue: false, // 内部
      payload: {
        examId: 0,
        sort: 'asc',
        page: 0,
        size: 99999,
        name: ''
      },
      tableData: [],
      total: 0,
      paperType: ''
    }
  },
  watch: {
    paperModal(val) {
      if (!val) {
        this.fullscreen = val
      }
    }
  },
  methods: {
    open(index) {
      if (index === 0) {
        if (this.$parent.ePaperId || this.paperType !== '4') {
          this.paperModal = true
          this.$nextTick(() => {
            this.$refs['editor'].questionInfo(this.$parent.ePaperId || this.$route.query.paperId)
          })
        } else {
          this.$message({
            message: '随机出卷无法预览',
            type: 'warning'
          })
        }
      } else {
        this.payload.examId = this.$parent.paperId
        this.determineTheCategory()
      }
    },
    determineTheCategory() {
      externalEmployeesApi.getExternalType(this.$parent.paperId).then(res => {
        if (res.code === 0) {
          if (res.res) {
            this.staffValue = true
            this.getExternalUser()
          } else {
            this.staffValue = false
            this.getShowStudents()
          }
        }
      })
    },
    //获取外部人员列表
    getExternalUser() {
      this.payload.examId = this.$parent.paperId
      externalEmployeesApi.getExternalUser(this.payload).then(res => {
        if (res.code === 0) {
          this.tableData = [...res.res.data]
          this.total = res.res.total
          this.staffModal = true
        }
      })
    },
    // 内部员工
    getShowStudents() {
      this.payload.examId = this.$parent.paperId
      newExamManageApi.showStudents(this.payload).then(res => {
        if (res.code === 0) {
          this.tableData = [...res.res.data]
          this.total = res.res.total
          this.staffModal = true
        }
      })
    },
    getAllExamInfo() {
      this.paperType = window.sessionStorage.getItem('paperType')
      newExamManageApi.examAllInfo(this.$parent.paperId).then(res => {
        Object.keys(res.res).forEach(r => {
          if (r === 'passScore') {
            res.res[r] = res.res[r] * 100 + '%'
          }
          if (r === 'limitTime') {
            res.res[r] = `${res.res[r] || 0}分钟`
          }
          this.examInfo.forEach(e => {
            if (e.key === r) {
              e.value = res.res[r]
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.finish-style {
  width: 53%;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  .info-style {
    text-align: left;
    margin-top: 25px;
    margin-right: 27px;
    width: 50%;
    .form-title {
      .font(14px, 500, #000);
      white-space: nowrap;
    }
    span {
      .font(14px, 500, #888);
      line-height: 20px;
    }
    .post-style {
      margin-right: 10px;
      text-align: center;
      border-radius: 2px;
      font-size: 12px;
      .wh(60px, 22px);
    }
  }
  .btn-div {
    margin-top: 30px;
  }
  .btn-style {
    .font(14px, 500, #448bff);
    .wh(350px, 38px);
    box-shadow: 0px 3px 6px rgba(60, 100, 209, 0.5);
    border-radius: 4px;
    &:last-child {
      margin-left: 12px;
    }
  }
  .btn {
    .wh(80px, 32px);
    border-radius: 4px;
  }
  .modalTitle {
    .font(16px, bold, #282c3d);
  }
  ::v-deep .el-dialog {
    min-width: 860px;
    max-width: 100%;
  }
  ::v-deep .el-dialog__body {
    padding: 20px 20px 0 20px;
  }
  ::v-deep .el-dialog__footer {
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dialog-footer {
  width: 100%;
  padding: 0 20px;
  .flexStyle(flex, space-between, center);
  ::v-deep .el-button {
    height: 32px;
    padding: 0;
  }
}
.paperView {
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>
