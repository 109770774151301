<template>
  <Form class="exam-form" ref="examForm" :model="examForm" :rules="ruleValidate" :label-width="90">
    <FormItem label="通知方式">
      <RadioGroup v-model="examForm.notifyType">
        <Radio :label="0">仅发送邮件</Radio>
        <Radio :label="1">仅发送短信</Radio>
        <Radio :label="2">发送邮件和短信</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem label="通知时间">
      <RadioGroup v-model="examForm.notifyTimeType">
        <Radio :label="0">定时通知</Radio>
        <Radio :label="1">立即通知</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem v-if="examForm.notifyTimeType === 0" label="选择时间" prop="notifyTime">
      <DateTimePicker v-model="examForm.notifyTime"></DateTimePicker>
    </FormItem>
    <FormItem label="通知内容" prop="notifyContent">
      <Input type="textarea" :rows="4" v-model="examForm.notifyContent" placeholder="请输入通知内容"></Input>
      <div class="form-tip" v-if="!ruleValidate.notifyContent.error">
        不超过 200 字
      </div>
    </FormItem>
    <Button size="small" style="margin-left: 90px" @click="handlePreStep">上一步</Button>&nbsp;&nbsp;
    <Button type="primary" size="small" @click="handleNextStep">下一步</Button>
  </Form>
</template>

<script>
import DateTimePicker from '@/components/common/DateTimePicker'

export default {
  name: 'ExamNotify',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DateTimePicker
  },
  data: function() {
    const that = this
    return {
      examForm: this.value,
      ruleValidate: {
        notifyContent: {
          type: 'string',
          max: 200,
          error: false,
          message: '通知内容不超过 200 字',
          validator: (rule, value, callback) => {
            if (value.length > 200) {
              that.ruleValidate.notifyContent.error = true
              callback(new Error('通知内容不超过 200 字'))
            } else {
              that.ruleValidate.notifyContent.error = false
              callback()
            }
          }
        }
      }
    }
  },
  methods: {
    handleNextStep() {
      this.$emit('on-success')
    },
    handlePreStep() {
      this.$emit('on-pre-click')
    }
  },
  watch: {
    examForm(newVal, oldVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="less" scoped>
.exam-form {
  width: 500px;
}

button {
  width: 60px;
}
</style>
