<template>
  <div class="yt-main yt-flex-layout">
    <div class="yt-container" :class="{ 'is-pack-up': isPackUps }" style="padding: 10px 0 0 0;">
      <div class="yt-content">
        <div class="yt-content-top">
          <!--          <div class="question-order">-->
          <!--            <span>试卷名称: {{ papernew.paperName }}</span>-->
          <!--          </div>-->
          <div class="info">
            <p>
              <YTIcon :href="'#icon-shijian'" style="width: 15px;height: 15px;fill: #999999" /><span>创建时间：</span
              >{{ $formatTime(new Date().getTime(), 'yyyy-MM-dd') }}
            </p>
            <p>
              <YTIcon :href="'#icon-shitixiangqing'" style="width: 15px;height: 15px;fill: #999999" /><span>试卷总分：</span
              >{{ papernew.totalPoints }}
            </p>
            <p><YTIcon :href="'#icon-fenlei'" style="width: 15px;height: 15px;fill: #999999" /><span>试题总量：</span>{{ papernew.questionCount }}</p>
          </div>
        </div>
        <div class="question-container" v-loading="questionListLoading" element-loading-text="加载中">
          <div
            class="list"
            :style="{
              overflowY: questionListLoading ? 'hidden' : 'auto',
              height: fullscreen ? '735px' : '490px'
            }"
          >
            <!--题目卡片-->
            <template>
              <div v-for="(item, index) in questionList">
                <div v-if="item.questionScores.length > 0">
                  <div class="question-sort">
                    <p>
                      {{ upperCase[index] }}、{{ item.name }}<span>{{ `(共${item.questionScores.length}题；共${item.totalScore}分)` }}</span>
                    </p>
                  </div>
                  <div
                    @mouseenter="selectRowId = question.id"
                    v-for="(question, qIndex) in questionList[index].questionScores"
                    :key="qIndex"
                    class="item"
                    :id="'anchor-' + question.id"
                  >
                    <div style="width: 100%">
                      <div class="body">
                        <!--题干-->
                        <div class="stem">
                          <div class="info">
                            {{ qIndex + 1 }}.&nbsp;
                            <div v-html="question.questionDetail.stem"></div>
                          </div>
                          <div class="button" @click="question.pStatus = !question.pStatus">
                            <span v-text="question.pStatus ? '展开' : '收起'"></span>
                            <YTIcon
                              :href="'#icon-open'"
                              @click="question.pStatus = !question.pStatus"
                              :style="{ transform: question.pStatus ? 'rotate(0deg)' : 'rotate(180deg)' }"
                            />
                          </div>
                        </div>
                        <component :is="questionComponents[question.questionDetail.questionType]" :question="question" style="margin-top: 16px" />
                        <div v-show="!question.pStatus" class="correct-answer">
                          <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                          <div v-html="question.questionDetail.questionAnswer"></div>
                        </div>
                        <div v-show="!question.pStatus" style="display: flex">
                          <div style="flex-shrink: 0">
                            <span style="letter-spacing: 12px">解</span>
                            <span style="letter-spacing: 5px">析:</span>
                          </div>
                          <div v-html="question.questionDetail.analysis"></div>
                        </div>
                        <div v-show="!question.pStatus" class="tag-and-knowledge" ref="tagAndKnowledge">
                          <div class="tk-item tk-select-item">
                            <span style="letter-spacing: 12px">标</span>
                            <span style="letter-spacing: 5px">签:</span>
                            <template v-for="(tag, tIndex) in question.questionDetail.tags">
                              <div :key="tIndex">
                                <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                                  <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                                    {{ tag.name }}
                                  </div>
                                </el-tooltip>
                              </div>
                            </template>
                            <el-popover
                              placement="top"
                              width="202"
                              trigger="hover"
                              title="题目标签"
                              v-if="
                                question.questionDetail.hasOwnProperty('tags') &&
                                  question.questionDetail.tags &&
                                  showSize < question.questionDetail.tags.length
                              "
                            >
                              <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                                <template v-for="(tagPop, tPopIndex) in question.questionDetail.tags">
                                  <div :key="tPopIndex">
                                    <el-tooltip
                                      effect="dark"
                                      :disabled="tagPop.name.length < 4"
                                      :content="tagPop.name"
                                      placement="top"
                                      :open-delay="1000"
                                    >
                                      <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                        {{ tagPop.name }}
                                      </div>
                                    </el-tooltip>
                                  </div>
                                </template>
                              </div>
                              <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px" />
                            </el-popover>
                          </div>
                        </div>
                      </div>
                      <!--题目卡片底部-->
                      <div v-show="!question.pStatus" class="footer">
                        <div>
                          <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.questionDetail.difficulty / 10 }}</p>
                          <p slot="reference" class="popover-reference">
                            使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount || 0 }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="empty"
                :style="{
                  height: 'calc(100vh - 303px)'
                }"
                v-if="questionAllid.length <= 0"
              >
                <template v-if="!questionListLoading" :style="height">
                  <YTIcon :href="'#icon-zanwushuju'" />
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import TrueOrFalse from '../../../question/list/questions/TrueOrFalse'
import Choice from '../../../question/list/questions/Choice'
import Code from '../../../question/list/questions/Code'
import ability from '@/api/ability'
import question from '@/api/question'
import paper from '@/api/paper'
import newExerciseExamApi from '@api/newExerciseExam'
import Manual from '@/components/manage/exam/exam/newPaper/Manual'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'PreviewPaper',
  props: ['fullscreen'],
  components: {
    YTIcon,
    TrueOrFalse,
    Choice,
    Manual,
    ConfirmDialog,
    Code
  },
  data() {
    return {
      defaultShowNodes: [],
      selectedQuestionIds: [],
      upperCase: ['一', '二', '三', '四', '五', '六'],
      qTypeName: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题'],
      addLoading: false,
      choiceQuestions: false,
      deleteLoading: false,
      saveLoading: false,
      paperInfo: [],
      drawer: false,
      selectRowId: -1,
      questionId: '',
      radioChange: '1',
      paperId: null,
      abilityId: '',
      questionType: null,
      addQuestionIds: [],
      delRows: [],
      removeQuestionIds: [],
      papernew: {
        paperName: '',
        questionCount: 0,
        totalPoints: 0,
        paperBankId: '',
        questionSimpleVOS: []
      },
      pageNum: 0,
      pageSize: 10,
      total: 0,
      questionAll: [], //已选用的全部题目
      questionAllid: [], //已选用的全部题目id
      questionSelection: [], //被选中题目
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      knowledgePointDownKeyword: '', //知识点筛选搜索
      knowledgePointsDown: [], //知识点筛选树
      questionPaperInfo: {}, //悬停显示使用了题目的试卷列表
      questionListLoading: false, //是否加载中
      showSize: 0, //根据屏幕宽度展示标签的个数
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      questionList: [], //已选用的题目列表
      questionLists: [], //未选用的题目列表
      difficulties: [
        { label: '简单', value: '3' },
        { label: '中等', value: '5' },
        { label: '困难', value: '7' }
      ],
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      isPackUp: true,
      isPackUps: false,
      keyword: '',
      keywords: '',
      treeData: [],
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: null }, ...this.ytConstant.questionType.getMenu()]
        }
      },
      statisticList: [],
      searchQuestionForm: {
        questionName: '',
        questionBankId: '',
        tagIds: [],
        searchForm: {
          questionType: null, //题型
          questionStatus: null, //状态
          difficultyType: null, //难度
          useCountDesc: '', //使用次数
          questionTagIds: [], //标签
          knowledgePointIds: [], //知识点
          haveKnowledge: true,
          hasKnowledge: false,
          hasTestCase: false
        }
      },
      sectionForms: []
    }
  },
  methods: {
    //批量删除弹窗
    delBatch() {
      if (this.questionSelection.length === 0) {
        return this.$message.warning('请选择删除的题目')
      }
      this.$refs['delBatchDialog'].open()
    },
    sortData(arr) {
      // 题目数据整理
      arr.forEach((a, index) => {
        // 移除重复的题目
        let find = this.papernew.questionSimpleVOS.findIndex(p => p.questionId === a.id || a.questionId)
        if (find !== -1) arr.splice(index, 1)
        a['score'] = a['points'] = 1
      })
      // 规范是否有新的题型分类 且该试卷有分类的  不是默认大题
      if (this.questionList && this.questionList[0].name !== '默认大题') {
        arr.forEach(a => {
          let find = this.questionList.findIndex(q => q.questionType === a.questionType)
          if (find === -1) {
            // 不存在  则新建
            this.questionList.push({
              level: 0,
              questionCount: 0,
              name: this.qTypeName[a.questionType],
              order: a.questionType + 1,
              questionScores: [],
              questionVOS: [],
              questionType: a.questionType,
              totalPoints: 0,
              totalScore: 0
            })
          }
        })
      }
      this.questionList.forEach(q => {
        arr.forEach(a => {
          if (this.questionList && this.questionList[0].name === '默认大题') {
            this.setRowData(a, q)
          } else {
            if (q.questionType === a.questionType) {
              this.setRowData(a, q)
            }
          }
        })
      })
      arr.forEach(a => {
        this.papernew.questionSimpleVOS.push({
          isChecked: false,
          questionId: a.id || a.questionId,
          score: 1
        })
      })
      this.questionList.forEach(q => {
        q['questionCount'] = q.questionScores.length
        q.totalScore = q.totalPoints = q.questionScores.reduce((pre, cur) => pre + cur.score, 0)
      })
      this.questionList.sort((a, b) => {
        return a.order - b.order
      })
      this.getSelectedQuestions()
    },
    setRowData(a, q) {
      q.questionScores.push({
        id: a.id,
        order: q.order,
        points: a.points || 0,
        codeQuestionTemplates: a.codeQuestionTemplates || null,
        questionDetail: {
          analysis: a.analysis,
          difficulty: a.difficulty,
          id: a.id,
          questionType: a.questionType,
          stem: a.stem,
          tags: a.tags || [],
          onStatus: true
        },
        questionId: a.id,
        useCount: a.useCount,
        score: a.score || 0
      })
    },
    addQuestionsModal() {
      // 添加题目
      this.choiceQuestions = true
    },
    //保存试卷
    async savePaper() {
      let a = false
      if (this.papernew.questionSimpleVOS.length <= 0) {
        this.$message.warning('试卷题目不能为空')
        return a
      }
      this.sectionForms = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.sectionForms.push({
          points: item.score === 0 ? 0.5 : item.score || 1,
          questionId: item.questionId
        })
      })
      let payload = {
        paperName: this.papernew.paperName,
        examId: this.$parent.examId,
        paperId: this.paperId,
        questionPoints: this.sectionForms
      }
      await paper.updatePaper(payload).then(res => {
        if (res.code === 0) {
          a = true
        }
      })
      return a
    },
    //删除该题型下的所有题目
    delQuestion(row) {
      this.delRows = row
      this.$refs['delConDialog'].open()
    },
    //批量移除题目
    movePaper() {
      this.questionSelection.forEach(d => {
        this.papernew.questionSimpleVOS.forEach((q, index) => {
          if (d === q.questionId) {
            this.papernew.questionSimpleVOS.splice(index, 1)
          }
        })
      })
      this.questionSelection = []
      this.$message.success('批量移除成功')
      // this.getPaperQuestiontree()
      // this.searchPaperSlectedQuestion()
      this.statisticalScore()
    },
    // 题目id定位当前位置
    selectorById(row) {
      this.selectRowId = row.id
      document.querySelector(`#anchor-${row.id}`).scrollIntoView({ behavior: 'smooth' })
    },
    // 获取题目信息
    questionInfo(data) {
      this.paperId = data
      if (data) {
        this.calculateSize(document.body.clientWidth)
        this.getPaperBrief(this.paperId)
        this.getPaperQuestiontree(this.paperId)
      } else {
        this.$message({
          message: '该试卷暂无考题',
          type: 'warning'
        })
      }
    },
    getPaperQuestiontree(data) {
      this.getSelectedQuestionIds()
      let payload = {
        keyword: this.keywords,
        paperId: data,
        selectedQuestionIds: this.selectedQuestionIds
      }
      ability.getPaperQuestionCount(payload).then(res => {
        if (res.code === 0) {
          this.treeData = res.res
          this.defaultShowNodes.push(this.abilityId)
          this.searchPaperSlectedQuestion()
          if (this.radioChange === '2') {
            this.searchPaperNotSelectedQuestion()
          }
        }
      })
    },
    //试卷总分统计
    statisticalScore() {
      let total = 0
      this.papernew.questionSimpleVOS.forEach(item => {
        total += parseFloat(item.score === 0 ? 0.5 : item.score)
      })
      this.papernew.totalPoints = total
    },
    setCurrentKey(id) {
      this.$nextTick(() => {
        this.$refs['abilty-tree'].setCurrentKey(id ? id : this.treeData[0].id)
      })
    },
    //设置大题分数
    setBigTopicPoint(item, index) {
      let total = 0
      let point = Math.floor(item / this.questionList[index].questionCount) === 0 ? 0.5 : Math.floor(item / this.questionList[index].questionCount)
      this.questionList[index].questionScores.forEach(q => {
        q.score = point
        total = total + point
        this.papernew.questionSimpleVOS.forEach(s => {
          if (s.questionId === q.id) {
            s.score = point
          }
        })
      })
      this.questionList[index].totalScore = total
      this.$forceUpdate()
      this.statisticalScore()
    },
    //设置小题分数
    setSmallTopicPoint(questionId, score, questionType) {
      if (this.questionList[0].name === '默认大题') {
        this.questionList[0].totalScore = 0
        this.questionList[0].questionScores.forEach(qs => {
          this.questionList[0].totalScore += Number(qs.score)
        })
      } else {
        score = Number(score)
        let total = 0
        if (score < 1 || score === '') {
          score = 0.5
        } else if (score > 1 && score.toString().indexOf('.') !== -1) {
          score = Math.floor(score)
        }
        this.questionList.forEach((q, index) => {
          if (this.ytConstant.questionType.getLabel(questionType) === q.name) {
            this.questionList[index].questionScores.forEach(s => {
              // let v = Number(s.score)
              // if (v < 1 || v) {
              //   v = 0.5
              // } else if (v > 1 && v.toString().indexOf('.') !== -1) {
              //   v = Math.floor(v)
              // }
              total = total + Number(s.score)
            })
            this.questionList[index].totalScore = total
          }
          this.$forceUpdate()
        })
      }
      this.papernew.questionSimpleVOS.forEach(item => {
        if (item.questionId === questionId) {
          item.score = score
        }
      })
      this.statisticalScore()
    },
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 10
      } else if (width > 1440 && width < 1920) {
        this.showSize = 15
      } else {
        this.showSize = 16
      }
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.questionSelection = this.questionAll.map(item => {
          item.isChecked = true
          return item.id
        })
      } else {
        this.questionAll.map(item => {
          item.isChecked = false
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    toFilterNodes(ref) {
      this.$refs[ref].filter(this.keywords)
      this.$refs[ref].setCheckedNodes([])
    },
    //获取试卷选中题目id
    getSelectedQuestionIds() {
      this.selectedQuestionIds = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.selectedQuestionIds.push(item.questionId)
      })
    },
    //根据条件查询试卷已选题目
    searchPaperSlectedQuestion() {
      this.questionListLoading = true
      this.getSelectedQuestionIds()
      // newExerciseExamApi
      //   .judgeHasPaperId(this.$parent.examId)
      paper
        .publicPaperInfo(this.paperId)
        .then(res => {
          if (res.code === 0) {
            this.questionList = res.res.sectionVOS.map(item => {
              item['questionCount'] = item.questionVOS.length
              if (item.name === '判断题') {
                item.questionType = 0
                item.questionScores = item.questionVOS.map(q => {
                  q.answer = q.questionAnswer === '正确'
                  return q
                })
              }
              if (item.name === '单选题') item.questionType = 1
              if (item.name === '多选题') item.questionType = 2
              if (item.name === '填空题') item.questionType = 3
              if (item.name === '简答题') item.questionType = 4
              if (item.name === '代码题') item.questionType = 5

              item.questionScores = item.questionVOS.map(t => {
                this.papernew.questionSimpleVOS.forEach(s => {
                  if (t.questionId === s.questionId) {
                    t.score = s.score
                  }
                })
                if (t.questionDetail.questionType === 1 || t.questionDetail.questionType === 2) {
                  // 单选或多选
                  t['options'] = t.questionDetail.options || []
                }
                if (t.questionDetail.questionType === 5) {
                  // 代码题
                  t['codeQuestionTemplates'] = t.questionDetail.codeQuestionTemplates || []
                }
                t['id'] = t['questionId']
                t['pStatus'] = true
                return t
              })

              item.totalScore = item.totalPoints = 0
              item.questionScores.forEach(t => {
                item.totalScore += parseFloat(t.points)
              })

              return item
            })
            this.sortPaperQuestions(this.questionList)
            this.getSelectedQuestions()
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    sortPaperQuestions(arr) {
      // 将试卷题型进行分类
      // 对象只有一条数据  且题型是默认大题的时候
      if (arr.length === 1 && arr[0].name === '默认大题') {
        let paperList = []
        const qList = arr[0].questionScores
        qList.forEach(q => {
          let find = paperList.findIndex(p => p.questionType === q.questionDetail.questionType)
          if (find === -1) {
            paperList.push({
              level: 0,
              questionCount: 0,
              name: this.qTypeName[q.questionDetail.questionType],
              order: q.questionDetail.questionType + 1,
              questionScores: [],
              questionVOS: [],
              questionType: q.questionDetail.questionType,
              totalPoints: 0,
              totalScore: 0
            })
          }
        })
        paperList.sort(function(a, b) {
          return a.order - b.order
        })
        // 每种题型添加题目
        qList.forEach(q => {
          paperList.forEach(p => {
            if (q.questionDetail.questionType === p.questionType) {
              p.questionScores.push(q)
            }
          })
        })
        // 设置每种题型总分数和总题数
        paperList.forEach(q => {
          q.questionCount = q.questionScores.length
          q.totalScore = q.totalPoints = q.questionScores.reduce((pre, cur) => {
            return (pre += cur.score)
          }, 0)
          q.questionVOS = q.questionScores
        })
        this.questionList = paperList
      }
    },
    //获取当前技能下所有已选题目的ID
    getSelectedQuestions() {
      this.questionAll = []
      this.questionAllid = []
      this.questionList.some(item => {
        item['questionScores'].map(m => {
          this.questionAll.push({ id: m.questionId, isChecked: false })
          this.questionAllid.push(m.questionId)
        })
      })
    },
    //根据条件分页查询试卷未选题目
    searchPaperNotSelectedQuestion() {
      this.getSelectedQuestionIds()
      this.questionListLoading = true
      let hasKnowledges = ''
      if (this.searchQuestionForm.searchForm.hasKnowledge && !this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = false
      } else if (!this.searchQuestionForm.searchForm.hasKnowledge && this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = true
      }
      let payload = {
        abilityId: this.abilityId,
        difficultyType: this.searchQuestionForm.searchForm.difficultyType,
        hasKnowledge: hasKnowledges,
        keyword: this.keyword,
        knowledgePointIds: this.searchQuestionForm.searchForm.knowledgePointIds,
        questionStatus: this.searchQuestionForm.searchForm.questionStatus,
        questionTagIds: this.searchQuestionForm.searchForm.questionTagIds,
        questionType: this.searchQuestionForm.searchForm.questionType,
        selectedQuestions: this.selectedQuestionIds,
        useCountDesc: this.searchQuestionForm.searchForm.useCountDesc
      }
      question
        .searchPaperNotSlectedQuestion(this.pageNum, this.pageSize, payload)
        .then(res => {
          if (res.code === 0) {
            this.questionLists = res.res.data
            this.total = res.res.total
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    //获取试卷信息
    getPaperBrief(data) {
      paper.getPaperBrief(data).then(res => {
        if (res.code === 0) {
          this.papernew = res.res
          let questionSimple = []
          if (this.papernew.questionSimpleVOS) {
            this.papernew.questionSimpleVOS.forEach(item => {
              questionSimple.push({ ...item, isChecked: false })
            })
          }
          this.papernew.questionSimpleVOS = questionSimple
        }
      })
    },
    reSearchQuestions() {
      this.pageNum = 0
      this.total = 0
      this.questionAll = []
      this.questionAllid = []
      this.searchPaperNotSelectedQuestion()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/filterDropdownMenu';
.yt-main {
  position: relative;
  text-align: left;
  padding-top: 0 !important;
  background: #f8f8f8 !important;
}
.yt-container {
  padding: 0 !important;
  width: 100%;
}
.yt-right--navigation {
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 20px;
  background: #fff;
  width: 270px;
  margin-left: 10px;
  .header {
    height: 50px;
    .font(14px, bold, #282c3d);
    align-items: center;
    padding-left: 20px;
    span {
      .font(16px, bold, #448bff);
    }
  }
  .hr-style {
    border: 1px solid #e2e4e8;
  }
  .question-type {
    padding: 10px 20px 0 20px;
    .q-style {
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    .type {
      width: 3px;
      height: 10px;
      background: #448bff;
      opacity: 1;
      border-radius: 2px;
      /*margin-top: 8px;*/
      margin-right: 5px;
    }
    .question-number {
      flex-wrap: wrap;
      .number {
        border-radius: 3px;
        .wh(24px, 24px);
        border: 1px solid #d9d9d9;
        margin: 10px 12px 0 0;
        &:hover {
          cursor: pointer;
        }
      }
      .selectRow {
        background: #448bff;
        border: 0;
        color: #fff;
      }
    }
  }
}
.yt-content {
  background-color: #f3f4f7;
  padding: 0;
  box-shadow: none;
}
.yt-content-top {
  .flexStyle(flex, flex-start, center);
  padding: 20px 20px 10px;
  margin-bottom: 10px;
  height: 83px;
  background: #ffffff;
  border-radius: 6px;
  .question-order {
    white-space: nowrap;
    height: 32px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    span {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
    }
  }
  .info {
    .flexStyle(flex, flex-start, center);
    svg {
      margin-right: 5px;
    }
    p {
      margin-right: 40px;
    }
  }
  .question-news {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    span {
      margin-right: 40px;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      opacity: 1;
    }
  }
}
//筛选列表
.filter-container {
  background: #ffffff;
  padding: 16px 20px 0;
  margin-bottom: 10px;
  position: relative;
  .input-container {
    position: absolute;
    width: 334px;
    right: 125px;
    top: 7px;
    ::v-deep .el-input__inner {
      height: 38px;
    }
  }
  .pack-up {
    position: absolute;
    width: 53px !important;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    right: 25px;
    top: 13px;
    .flexStyle(flex, flex-start, center);
    p {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      user-select: none; //不被选中
    }

    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 20px;
    margin-bottom: 16px;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: @small;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
    }
    .item {
      height: 20px;
      line-height: 20px;
      margin: 0 8px;
      padding: 0 15px;
      font-size: @small;
      cursor: pointer;
    }
    .default-item {
      &:hover {
        border-radius: 11px;
        background: #448bff;
        color: #ffffff;
      }
    }
    .active {
      border-radius: 11px;
      background: #448bff;
      color: #ffffff;
    }
    .checkbox-container {
      .flexStyle(flex, flex-start, center);
      border-left: 1px solid #d8d8d8;
      margin-left: 17px;
      .el-checkbox {
        margin-left: 40px;
        margin-right: 0;
        ::v-deep .el-checkbox__label {
          font-size: @small;
          padding-left: 6px;
          color: #000000 !important;
        }
      }
    }
    .other-item {
      padding: 0;
      margin: 0 22px 0 8px;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      user-select: none; //禁止双击选中文字
      svg {
        color: #a6a6a6;
      }
    }
    .asc {
      transform: rotate(180deg);
    }
    .select {
      font-size: @small;
      &:hover {
        color: #448bff;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .el-dropdown {
      .flexStyle(flex, flex-start, center);
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
    .el-icon-arrow-down {
      font-size: @small;
      color: #999999 !important;
      transition: all 0.2s ease-in-out;
    }
  }
  .statistic-question {
    height: 65px;
    .question-border {
      .wh(170px, 60px);
      border: 1px solid #e2e4e8;
      border-radius: 6px;
      .img {
        width: 50px;
      }
      .content {
        flex-direction: column;
        justify-content: space-around;
        width: calc(100% - 50px);
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
//全选
.select-all {
  padding-left: 20px;
  height: 55px;
  .flexStyle(flex, space-between, center);
  background: #ffffff;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  .btn-style {
    margin-right: 40px;
    span {
      margin-left: 8px;
    }
  }
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  .list {
    background-color: #ffffff;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      margin-left: 20px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header,
    .body,
    .footer {
      padding-left: 20px;
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    //题目部分
    .body {
      font-size: @small;
      padding-right: 20px;
      .stem {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        .flexStyle(flex, space-between, flex-start);
        .info {
          display: flex;
        }
        .button {
          .flexStyle(flex, center, center);
          width: 54px;
          height: 20px;
          background: #f7f7f7;
          border: 1px solid #e6e6e6;
          opacity: 1;
          border-radius: 13px;
          user-select: none;
          cursor: pointer;
          span {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #888888;
            margin-right: 4px;
            white-space: nowrap;
          }
          svg {
            transition: all 0.2s ease-in-out;
            fill: #888888;
          }
        }
      }
      .stem-code {
        height: 200px;
        margin-right: 40px;
        margin-top: 15px;
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px dotted #e9e9e9;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
        }
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      .el-input {
        max-width: 60px;
        ::v-deep .el-input__inner {
          height: 20px;
          border: 1px solid #d9d9d9;
          text-align: center;
        }
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #448bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
.question-sort {
  width: 100%;
  height: 50px;
  opacity: 1;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  padding: 15px 0px 15px 20px;
  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: #000000;
    span {
      font-weight: 400;
      color: #999999;
    }
  }
  .el-input {
    width: 70px;
    ::v-deep .el-input__inner {
      height: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      text-align: center;
    }
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
.btn-del:hover,
.del-svg:hover {
  svg {
    fill: #448bff !important;
  }
}
::v-deep .yt-container-flex .yt-content {
  width: calc(100% - 10px - 280px);
}
::v-deep .yt-paper--body {
  height: auto !important;
}
::v-deep .el-input__inner {
  height: 20px;
  border: 1px solid #d9d9d9;
  text-align: center;
}
</style>
