<template>
  <div class="container">
    <div class="paper-left">
      <el-menu class="yt-menu" style="background-color: #0000 !important" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="0">
          学校试卷
        </el-menu-item>
        <template>
          <el-menu-item index="1">
            平台试卷
          </el-menu-item>
        </template>
      </el-menu>
      <el-input class="input" v-model="searchForm.searchContent" placeholder="根据名称搜索试卷" @keydown.enter.native="getSearchList"
        ><i slot="suffix" class="el-input__icon el-icon-search" @click="getSearchList"></i
      ></el-input>
      <div class="paper-list" :style="{ height: !fullscreen ? '415px' : '634px' }">
        <div :class="['paper-info', paperId === data.paperId ? 'menu' : '']" v-for="data in paperList" :key="data.paperId" @click="selectPaper(data)">
          <p>{{ data.paperName }}</p>
          <div class="paper-fraction">
            <span>试卷总分：{{ data.totalPoints }}分</span>
            <span>题目数量：{{ data.questionAmount }}题</span>
          </div>
        </div>
      </div>
    </div>
    <!-- :class="{ 'is-empty': !questionList.length }" -->
    <div class="paper-right">
      <p>
        <b>{{ paperName }}</b>
      </p>
      <div class="filter-container">
        <template v-for="(filter, key, index) in filters">
          <div :key="index" class="row">
            <div class="label">{{ filter.label }}:</div>
            <template>
              <div
                v-for="(child, fIndex) in filter.children"
                :key="fIndex"
                :class="['item default-item', sType === child.label ? 'active' : '']"
                @click="handleClickFilter(child.label)"
              >
                {{ child.label }}
              </div>
            </template>
          </div>
        </template>
        <div class="yt-question-info" :style="{ height: !fullscreen ? '433px' : '655px' }" v-loading="loading" element-loading-text="加载中">
          <div class="yt-question-content" v-if="questionList.length">
            <div v-for="(item, index) in questionList" :key="index">
              <template v-if="questionList.length > 0">
                <div
                  v-for="(question, index) in questionList[index].questionVOS"
                  :key="index"
                  class="item"
                  style="padding: 0;"
                  :id="'anchor-' + question.questionId"
                >
                  <div style="width: 100%;text-align: start;">
                    <!--题目卡片头部-->
                    <div class="header">
                      <div class="question-type">
                        {{ ytConstant.questionType.getLabel(question.questionType) }}
                      </div>
                      <!-- <p class="audit">
                        <YTIcon :href="auditIcons[question.status]"></YTIcon>
                      </p> -->
                    </div>
                    <div class="body">
                      <!--题干-->
                      <div class="stem">
                        {{ index + 1 }}.&nbsp;
                        <div v-html="question.stem"></div>
                      </div>
                      <MonacoEditor
                        v-if="question.hasCodeStem"
                        class="stem-code"
                        :editorId="question.id + question.codeStem.codeType + index"
                        :value="question.codeStem.template"
                        :language="codeTypes[question.codeStem.codeType]"
                      ></MonacoEditor>
                      <component :is="questionComponents[question.questionType]" :question="question" style="margin-top: 16px"></component>
                      <div class="correct-answer">
                        <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                        <div v-html="ytConstant.answerType.getLabel(question)"></div>
                      </div>
                      <div style="display: flex">
                        <div style="flex-shrink: 0">
                          <span style="letter-spacing: 12px">解</span>
                          <span style="letter-spacing: 5px">析:</span>
                        </div>
                        <div v-html="question.analysis"></div>
                      </div>
                      <div class="tag-and-knowledge" ref="tagAndKnowledge">
                        <div class="tk-item tk-select-item">
                          <span style="letter-spacing: 12px">标</span>
                          <span style="letter-spacing: 5px">签:</span>
                          <template v-for="(tag, tIndex) in question.tags">
                            <div :key="tIndex">
                              <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                                <div class="tk-point" :key="tIndex" v-if="tIndex < 5">
                                  {{ tag.name }}
                                </div>
                              </el-tooltip>
                            </div>
                          </template>
                          <el-popover
                            placement="top"
                            width="202"
                            trigger="hover"
                            title="题目标签"
                            v-if="question.tags !== null && question.tags.length > 5"
                          >
                            <div style="margin-bottom: 5px" class="tab-list">
                              <template v-for="(tagPop, tPopIndex) in question.tags">
                                <div :key="tPopIndex">
                                  <el-tooltip
                                    effect="dark"
                                    :disabled="tagPop.name.length < 4"
                                    :content="tagPop.name"
                                    placement="top"
                                    :open-delay="1000"
                                  >
                                    <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= 5" style="margin-bottom: 5px">
                                      {{ tagPop.name }}
                                    </div>
                                  </el-tooltip>
                                </div>
                              </template>
                            </div>
                            <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <!--题目卡片底部-->
                    <div class="footer">
                      <div>
                        <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                        <p>
                          {{ ytConstant.difficultType.getLabel(question.difficulty) }}
                        </p>
                        <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-else>
            <div class="is-empty">
              <YTIcon :href="'#icon-zanwushuju'" slot="reference" style="width:120px;height:124px;"></YTIcon>
              <p>暂时没有内容</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newPaper from '@/api/newPaper'
import paperApi from '@api/paper'
import YTIcon from '@components/common/YTIcon'
import TrueOrFalse from '../../../question/list/questions/TrueOrFalse'
import Choice from '../../../question/list/questions/Choice'
import Code from '@components/manage/question/list/questions/Code'
export default {
  name: 'SelectPaper',
  components: { YTIcon },
  props: ['state', 'fullscreen'],
  data() {
    return {
      activeIndex: '0',
      Content: '',
      dialogVisible: false,
      loading: true,
      sType: '全部',
      paperId: 0,
      questionList: [],
      questionListC: [],
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      paperInfo: [],
      paperName: '',
      searchForm: {
        isOrganizationPaper: true,
        searchContent: null
      },
      paperList: [],
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: '' }, ...this.ytConstant.questionType.getMenu()]
        }
      }
    }
  },
  mounted() {
    this.getSearchList()
  },
  watch: {
    state(value) {
      if (value) {
        this.getSearchList()
      }
    }
  },
  methods: {
    getSearchList() {
      this.loading = true
      newPaper
        .getPaperList(this.searchForm)
        .then(res => {
          if (res.code === 0) {
            this.paperList = [...res.res]
            this.paperId = res.res[0].paperId
            this.getPaperList(res.res[0].paperId)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectMenu(data) {
      this.searchForm.isOrganizationPaper = !Number(data)
      this.getSearchList()
    },
    selectPaper(data) {
      this.sType = '全部'
      this.paperId = data.paperId
      this.getPaperList(data.paperId)
    },
    handleClickFilter(value) {
      this.sType = value
      let list = []
      if (value !== '全部') {
        list = this.questionListC.filter(data => {
          return value === data.name
        })
      } else {
        list = [...this.questionListC]
      }
      this.questionList = [...list]
    },
    //获取题目
    getPaperList(id) {
      this.loading = true
      paperApi
        .getAllPaperInfoById(id)
        .then(res => {
          this.paperBankId = res.res.paperBankId
          this.paperName = res.res.name
          this.paperInfo.push(this.$formatTime(res.res.createTime, 'yyyy-MM-dd'))
          this.paperInfo.push(res.res.totalPoints)
          this.paperInfo.push(res.res.questionAmount)
          this.isOwner = res.res.isOwner
          this.questionList = res.res.sectionVOS
          this.questionListC = [...res.res.sectionVOS]
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables.less';
@import '~@/theme/paperDetail';
.container {
  .flexStyle(flex, center, center);
  // min-height: 520px;
  min-width: 1040px;
  // height: 100%;
  .paper-left {
    // min-height: 520px;
    // width: calc(100% / 4 + 91px);
    width: 25%;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    .input {
      padding: 12px 10px 10px;
    }
    .yt-menu {
      display: flex;
      justify-content: center;
      margin-left: 0 !important;
    }
    .el-input__icon {
      cursor: pointer;
      width: 37px;
      line-height: 67px;
    }
    .paper-list {
      //   padding: 0 10px;
      overflow-y: auto;
      .paper-info {
        height: 57px;
        padding: 0 10px;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        p {
          width: 239px;
          font-size: 12px;
          font-weight: bold;
          line-height: 28px;
          color: #000000;
          text-align: left;
          min-height: 28px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .paper-fraction {
          .flexStyle(flex, flex-start, center);
          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #888888;
            &:first-child {
              padding-right: 10px;
            }
          }
        }
        &:hover {
          background: #edf4ff;
          p,
          span {
            color: #448bff;
          }
        }
      }
    }
  }
  .menu {
    p,
    span {
      color: #448bff !important;
    }
  }
  .paper-right {
    // width: 75%;
    // max-width: 771px;
    width: 100%;
    max-width: 950px;
    // min-height: 520px;
    margin-left: 10px;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    padding: 20px 0 0 20px;
    p {
      margin-bottom: 16px;
      font-size: 14px;
      /*font-weight: bold;*/
      line-height: 28px;
      color: #000000;
      text-align: left;
    }

    .yt-question-info {
      margin-top: 0;
      overflow-y: auto;
      .body {
        width: calc(100% - 10px) !important;
      }
      .tk-item {
        width: 100% !important;
      }
      ::v-deep img {
        width: 100% !important;
      }
    }
    .filter-container {
      //   border-bottom: 1px solid #e2e4e8;
      position: relative;
      .pack-up {
        position: absolute;
        width: 53px !important;
        height: 25px;
        line-height: 25px;
        background: #f7f7f7;
        border-radius: 13px;
        border: 1px solid #e6e6e6;
        cursor: pointer;
        right: 25px;
        top: 13px;
        .flexStyle(flex, flex-start, center);
        p {
          margin-left: 8px;
          font-size: @small;
          width: 24px !important;
          user-select: none; //不被选中
        }

        svg {
          width: 9px;
          height: 14px;
          margin-left: 4px;
          margin-top: 14px;
          margin-right: 6px;
          transition: all 0.2s ease-in-out;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        .active {
          -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .row {
        .flexStyle(flex, flex-start);
        margin-bottom: 10px;
        height: 20px;
        color: #000000;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          font-size: @small;
          margin-right: 20px;
          height: 20px;
          font-weight: 400;
          line-height: 22px;
          color: #000000;
        }
        .item {
          height: 20px;
          line-height: 20px;
          margin: 0 8px;
          padding: 0 15px;
          font-size: @small;
          cursor: pointer;
        }
        .default-item {
          &:hover {
            border-radius: 11px;
            background: #448bff;
            color: #ffffff;
          }
        }
        .active {
          border-radius: 11px;
          background: #448bff;
          color: #ffffff;
        }
        .checkbox-container {
          .flexStyle(flex, flex-start, center);
          border-left: 1px solid #d8d8d8;
          margin-left: 17px;
          .el-checkbox {
            margin-left: 40px;
            margin-right: 0;
            ::v-deep .el-checkbox__label {
              font-size: @small;
              padding-left: 6px;
              color: #000000 !important;
            }
          }
        }
        .other-item {
          padding: 0;
          margin: 0 22px 0 8px;
          -moz-user-select: none; /*火狐*/
          -webkit-user-select: none; /*webkit浏览器*/
          -ms-user-select: none; /*IE10*/
          user-select: none; //禁止双击选中文字
          svg {
            color: #a6a6a6;
          }
        }
        .asc {
          transform: rotate(180deg);
        }
        .select {
          font-size: @small;
          &:hover {
            color: #448bff;
            i {
              transform: rotate(180deg);
            }
          }
        }
        .el-dropdown {
          .flexStyle(flex, flex-start, center);
          height: 20px;
          line-height: 20px;
          color: #000000;
        }
        .el-icon-arrow-down {
          font-size: @small;
          color: #999999 !important;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
.is-empty {
  height: 100%;
  .flexStyle(flex, center, center);
  flex-direction: column;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #666666 !important;
    margin-bottom: 0 !important;
  }
}
.prompt {
  .flexStyle(flex, center, center);
  flex-direction: column;
  svg {
    fill: #ff5050;
    margin-right: 5px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
  }
  .format {
    .flexStyle(flex, center, center);
    span {
      white-space: nowrap;
      margin-right: 12px;
    }
    .input {
      width: 332px;
      height: 37px;
      margin-left: 12px;
    }
    ::v-deep .el-input__inner {
      height: 37px;
      line-height: 37px;
    }
    &:first-child {
      margin-bottom: 30px;
    }
  }
}
.dialog-footer {
  .flexStyle(flex, center, center);
  .el-button {
    width: 80px;
  }
}
.stem {
  ::v-deep pre {
    white-space: normal !important;
  }
}
</style>
