<template>
  <div>
    <Form class="paper-form" ref="searchForm" :model="search" :label-width="60">
      <FormItem label="试卷库">
        <Select v-model="search.paperBankId" @on-change="handlePaperBankChange" filterable>
          <Option v-for="item in paperBankList" :key="item.paperBankId" :value="item.paperBankId || -1">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="关键字">
        <Input v-model="search.keywords" placeholder="请输入" />
      </FormItem>
      <FormItem label="创建时间">
        <DatePicker
          type="daterange"
          v-model="search.createTime"
          split-panels
          placeholder="请选择创建时间范围"
          :options="dateOptions"
          :editable="false"
          style="width: 100%"
        ></DatePicker>
      </FormItem>
      <FormItem label="试卷类型">
        <Select v-model="search.paperType">
          <Option :value="0">固定试题</Option>
          <Option :value="1">随机试题</Option>
        </Select>
      </FormItem>
      <FormItem>
        <Button type="primary" size="small" @click="searchPaper" style="padding: 4px 20px">搜索</Button>
      </FormItem>
    </Form>
    <Table :columns="columns" :data="papers.data" class="flex-table" :loading="loading">
      <template slot-scope="{ row, index }" slot="name">
        <div class="nowrap" :title="row.name">{{ row.name }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="number">
        <div class="nowrap" :title="row.paperNumber">{{ row.paperNumber }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="type">
        <div class="nowrap">固定试题</div>
      </template>
      <template slot-scope="{ row, index }" slot="use-times">
        <div class="nowrap" :title="row.usedTimes">{{ row.usedTimes }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="question-count">
        <div class="nowrap" :title="row.questionAmount">
          {{ row.questionAmount }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="total">
        <div class="nowrap" :title="row.totalPoints">{{ row.totalPoints }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="creator">
        <div class="nowrap" :title="row.creatorName">{{ row.creatorName }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="create-time">
        <div class="nowrap" :title="row.createTime">
          {{ row.createTime | formatTime('yyyy-MM-dd hh:mm') }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="opt">
        <div class="operation">
          <span @click="handlePreviewPaper(row)">预览</span>
          <span @click="handleSelectPaper(row)">选择</span>
        </div>
      </template>
    </Table>
    <Page
      show-total
      show-sizer
      show-elevator
      :total="papers.total"
      :current="pageNumber + 1"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
      style="margin-top: 10px;float: right"
    />
  </div>
</template>

<script>
import paperApi from '@api/paper'

const ALL_PAPER_BANK = {
  id: -1,
  name: '全部'
}

export default {
  name: 'PaperSelect',
  data: function() {
    return {
      search: {
        paperBankId: ALL_PAPER_BANK.id,
        keywords: '',
        createTime: [],
        paperType: 0
      },
      paperBankList: [ALL_PAPER_BANK],
      pageNumber: 0,
      pageSize: 10,
      columns: [
        {
          title: '试卷名称',
          slot: 'name',
          key: 'name',
          className: 'name-column',
          sortable: true,
          tooltip: true
        },
        {
          title: '试卷编号',
          slot: 'number',
          className: 'number-column'
        },
        {
          title: '类型',
          slot: 'type',
          className: 'type-column'
        },
        {
          title: '使用次数',
          slot: 'use-times',
          className: 'use-times-column',
          sortable: true
        },
        {
          title: '题量',
          key: 'questionAmount',
          slot: 'question-count',
          className: 'question-count-column',
          sortable: true
        },
        {
          title: '总分',
          slot: 'total',
          key: 'totalPoints',
          className: 'total-column',
          sortable: true
        },
        {
          title: '创建人',
          slot: 'creator',
          className: 'creator-column'
        },
        {
          title: '创建时间',
          slot: 'create-time',
          className: 'create-time-column'
        },
        {
          title: '操作',
          slot: 'opt',
          className: 'opt-column'
        }
      ],
      papers: {
        total: 0,
        data: []
      },
      loading: false
    }
  },
  created() {
    paperApi.getAllPaperBanks().then(res => {
      this.paperBankList = [ALL_PAPER_BANK].concat(res.res)
    })
  },
  methods: {
    handlePaperBankChange() {},
    searchPaper() {
      this.loading = true
      paperApi
        .searchPaper(this.pageNumber, this.pageSize, {
          paperBankId: this.search.paperBankId === -1 ? null : this.search.paperBankId,
          name: this.search.keywords,
          startCreateTime: this.search.createTime.length ? this.search.createTime[0].valueOf() : null,
          endCreateTime: this.search.createTime.length ? this.search.createTime[1].valueOf() : null
        })
        .then(res => {
          this.papers = res.res
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePreviewPaper(row) {
      window.open(`/manage/exam/paper/preview/${row.paperId}`)
    },
    handleSelectPaper(row) {
      this.$emit('on-paper-select', row)
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.searchPaper()
    },
    changePageSize(size) {
      this.pageSize = size
      this.searchPaper()
    }
  },
  computed: {
    dateOptions() {
      return {
        shortcuts: [
          {
            text: '近一周',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          },
          {
            text: '近一个月',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            }
          },
          {
            text: '近三个月',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';

.paper-form {
  width: 500px;
}

::v-deep .flex-table {
  border-bottom: 1px solid #e8eaec;

  .name-column {
    width: 20%;
  }

  .number-column {
    width: 10%;
  }

  .type-column {
    width: 10%;
    min-width: 80px;
  }

  .use-times-column {
    width: 10%;
    min-width: 106px;
  }

  .question-count-column {
    width: 7%;
    min-width: 80px;
  }

  .total-column {
    width: 7%;
    min-width: 80px;
  }

  .creator-column {
    width: 10%;
    min-width: 80px;
  }

  .create-time-column {
    width: 15%;
  }

  .opt-column {
    width: 10%;

    .operation span {
      cursor: pointer;
      margin-right: 10px;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }
  }
}
</style>
