<template>
  <div class="yt-paper--style" id="paperId">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/manage/exam/list'">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item style="color: #4579EA">{{ breadTitle }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-paper--body">
      <div class="layout-style yt-flex-layout">
        <el-steps class="el-steps--style yt-flex-layout" :active="nowStep" align-center>
          <el-step title="基本信息" />
          <el-step title="制作试卷" />
          <el-step title="设置考试人员" />
          <el-step title="完成" />
        </el-steps>
      </div>
      <div class="exam-body--content">
        <FirstStep @getName="getName" ref="first" v-show="nowStep === 1" />
        <SelectPaperMethods :name="name" ref="second" @setId="setId" v-show="nowStep === 2" />
        <staff ref="third" v-show="nowStep === 3" />
        <FinishPage ref="finish" v-show="nowStep === 4" />
      </div>
    </div>
    <div v-show="!showFooter" class="yt-footer--btn">
      <el-button class="el-button--default el-button--style" @click="nowStep === 1 ? backStep() : upStep()">{{
        nowStep === 1 ? '返回' : '上一步'
      }}</el-button>
      <el-button :loading="loading" type="primary" class="button" @click="nowStep === 4 ? finishExam() : nextStep()">{{
        nowStep === 4 ? '发布' : '下一步'
      }}</el-button>
    </div>
  </div>
</template>

<script>
import FirstStep from '@components/manage/exam/exam/newPaper/FirstStep'
import SelectPaperMethods from '@components/manage/exam/exam/newPaper/SelectPaperMethods'
import staff from '@components/manage/exam/exam/newPaper/InternalStaff'
import FinishPage from '@components/manage/exam/exam/newPaper/FinishPage'
import newExamManageApi from '@api/newExamManage'
import NewExamVue from '../../../course/exam/NewExam.vue'
export default {
  name: 'index',
  components: { FirstStep, SelectPaperMethods, staff, FinishPage },
  data() {
    return {
      examType: 0, // 考试用途类型 0 -》内部评测 1-》外部评测
      nowStep: 1,
      showFooter: false,
      loading: false, // btn 防抖
      isPaperEditor: false, // 为true时  编辑考试信息
      stepTip: '下一步',
      paperId: null, // 当他不为空的时候说明已存在考试，无需重复创建新的考试
      ePaperId: null,
      breadTitle: '发布考试',
      name: null
    }
  },
  watch: {
    nowStep: {
      handler(val) {
        switch (val) {
          case 2:
            this.$refs['second'].judgeHasPaper(this.isPaperEditor)
            return (this.breadTitle = '制作试卷')
          case 3:
            return (this.breadTitle = '设置考试人员')
          case 4: {
            this.$refs['finish'].getAllExamInfo()
            return (this.breadTitle = '发布考试')
          }
          default:
            return (this.breadTitle = '发布考试')
        }
      },
      deep: true
    }
  },
  mounted() {
    let res = this.$handleParams('newPaperData')
    this.isPaperEditor = res !== null ? res.isSetPaperInfo : false
    if (this.isPaperEditor) this.setPaperInfo()
  },
  beforeDestroy() {
    window.sessionStorage.removeItem('newPaperData')
  },
  methods: {
    setId(id) {
      this.ePaperId = id
    },
    setPaperInfo() {
      // 发布试卷的时候 校验试卷是否设置试题或者人员
      let res = this.$handleParams('newPaperData')
      this.nowStep = Number(res.stepIndex)
      this.paperId = res.examId
      this.$refs['first'].examInfo(this.paperId)
      if (this.nowStep === 2) {
        // 考题
        this.$refs['second'].judgeHasPaper(true)
      }
      if (this.nowStep === 3) {
        // 获取人员
        this.$refs['third'].determineTheCategory()
      }
    },
    backStep() {
      // 退出创建考试
      this.$router.push('/manage/exam/list')
    },
    finishExam() {
      // 最后  发布考试接口
      this.loading = true
      newExamManageApi
        .postExam(this.paperId)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('考试发布成功')
            this.$router.push('/manage/exam/list')
            window.sessionStorage.removeItem('newPaperData')
          } else {
            this.$message.error(res.res)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getName(name) {
      this.name = name
    },
    nextStep() {
      this.loading = true
      if (this.nowStep === 1) {
        // 创建考试信息 存在考试id直接下一步无需重复创建
        const res = this.$refs['first'].submitFirstStep()
        res
          .then(r => {
            if (r === true) this.nowStep += 1
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.nowStep += 1
        this.loading = false
      }
    },
    upStep() {
      this.nowStep--
    }
  }
}
</script>

<style lang="less" scoped>
.yt-paper--style {
  height: 100%;
  background: #f6f7fa;
  min-width: 1200px;
  overflow-x: auto;
  .content-wrapper {
    align-items: center;
    height: 35px;
  }
  .yt-paper--body {
    .layout-style {
      background: #fff;
      justify-content: center;
      .wh(100%, 95px);
      ::v-deep .el-steps--style {
        .wh(65%, 100%);
        align-items: center;
        .el-step__title {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .exam-body--content {
      margin-top: 10px;
      background: #fff;
      height: calc(100% - 115px);
      overflow-y: auto;
    }
    background: #f8f8f8;
    padding: 10px 10px 0;
    height: calc(100% - 79px);
    box-shadow: 0 3px 10px rgba(60, 100, 209, 0.1);
  }
  .yt-footer--btn {
    height: 69px;
    width: calc(100% - 20px);
    margin: 0 auto;
    background: #fff;
    animation: downToUp 0.5s;
    .el-button--style {
      margin-right: 2px;
    }
    .flexStyle();
  }
}
::v-deep .el-step__title.is-finish {
  .font(14px, 500, #448bff);
}
::v-deep .el-step__title.is-process,
::v-deep .el-step__head.is-process {
  .el-step__icon.is-text {
    border-color: #888;
  }
  .font(14px, 500, #888);
}
@keyframes downToUp {
  to {
    transform: translateY(0);
  }
  from {
    transform: translateY(45px);
  }
}
</style>
